import axios from 'axios';

export const FETCH_POST = 'fetch_post';
export const LOGIN_USER = 'login_user';
export const LOGOUT_USER = 'logout_user';
export const GET_SCHULNAME = 'get_schulname';
export const SET_PROGRAMM ='set_programm'
export const GET_FREISCHALTUNG ='get_freischaltung'
export const SET_ACTIVE_SCHUELER = 'set_active_schueler'
export const SET_ACTIVE_SCHULE = 'set_active_schule'
export const SET_GLOBALEMODULE_MODUS = 'set_globalemodule_modus'


export function loginUser (values){
  
  const request =  axios.post('/api/benutzer/check/', values)
  return {
    type: LOGIN_USER,
    payload: request
  };
}


export function logoutUser (){

  const sessionUser={
    userID:null
  }
  const request =  axios.post('/api/session', sessionUser)
  return {
    type: LOGOUT_USER,
    payload: request
  };
}


export function fetchPost(tag, id) {
  const request = axios.get(`/api/${tag}/${id}`);
    //'/api/' + tag + '/' + id);

  return{
    type: FETCH_POST,
    payload: request
  }

}

export function getSchulname (){

  const request =  axios.get('/api/simplevalue/schule')
  return {
    type: GET_SCHULNAME,
    payload: request
  };
}

export function setProgramm(programm){

  return{
    type: SET_PROGRAMM,
    param:programm
  }
}

export function getFreischaltung (){
 const request =  axios.get('/api/execcommonquery/freischaltung')
  return {
    type: GET_FREISCHALTUNG,
    payload: request
  };
}



export function setActiveSchueler(schueler){

  return{
    type: SET_ACTIVE_SCHUELER,
    param:schueler
  }
}


export function setActiveSchule(schule){

  return{
    type: SET_ACTIVE_SCHULE,
    param:schule
  }
}

export function setGlobaleModuleModus(modus){
  return{
    type: SET_GLOBALEMODULE_MODUS,
    param:modus
  }

}

import React from 'react'

import 'material-icons/iconfont/material-icons.css';

const BenutzerPanel =(props) => {   

        if (props.activeBenutzer===undefined) return null 


        let trLehrerBearbeiten=(

                            <tr>
                                <td colSpan="2">
                                    <div className="togglebutton">
                                        <label>
                                            <input type="checkbox" checked={props.activeBenutzer.kann_lehrpersonen_bearbeiten===1} name="kann_lehrpersonen_bearbeiten" onChange={props.aktualisiereBenutzer}/>
                                            <span className="toggle"></span>
                                            kann Lehrpersonen hinzufügen, bearbeiten und entfernen
                                        </label>
                                    </div>
                                </td>
                            </tr>
        )
        if (props.kann_lehrpersonen_bearbeiten===0)trLehrerBearbeiten=null


        let trSchuleBearbeiten=(
                            <tr>
                                <td colSpan="2">
                                    <div className="togglebutton">
                                        <label>
                                            <input type="checkbox" checked={props.activeBenutzer.kann_schuldaten_bearbeiten===1} name="kann_schuldaten_bearbeiten" onChange={props.aktualisiereBenutzer}/>
                                            <span className="toggle"></span>
                                            kann die Schuldaten bearbeiten
                                        </label>
                                    </div>
                                </td>
                            </tr>

        )
        if (props.kann_schuldaten_bearbeiten===0)trSchuleBearbeiten=null


        return(
            
              <div className="table-responsive">
                   
                    <table className="table">
                        <tbody>
                            <tr>
                                <td width="120">Vorname und Name *</td><td colSpan="2"><input value={props.activeBenutzer.namevoll} className="form-control" name="namevoll"  type="text" placeholder="Vorname und Name" onChange={props.aktualisiereBenutzer} /></td>
                            </tr>
                            <tr>
                                <td>Benutzername *</td><td colSpan="2"><input value={props.activeBenutzer.benutzername} className="form-control" name="benutzername"  type="text" placeholder="Benutzername" onChange={props.aktualisiereBenutzer} /></td>
                            </tr>
                            <tr>
                                <td>Email</td><td colSpan="2"><input value={props.activeBenutzer.email} className="form-control" name="email"  type="email" placeholder="Email" onChange={props.aktualisiereBenutzer} /></td>
                            </tr>
                            {trLehrerBearbeiten}
                            {trSchuleBearbeiten}
                        </tbody>
                    </table>
                </div>
        )

}

export default BenutzerPanel
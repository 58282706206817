import React, { Component } from 'react';
import axios from 'axios';
import browserHistory from 'react-router/lib/browserHistory';
import { setProgramm, loginUser, logoutUser} from '../actions'
import { connect } from 'react-redux';
import CacheBuster from 'react-cache-buster';
import {shuffleArray} from '../components/generalfunctions.jsx'
import { version, date } from '../../package.json';
import 'material-icons/iconfont/material-icons.css';
import {Helmet} from 'react-helmet'



class App extends Component {    

    constructor(props) {
        super(props);

        
    } 

    componentWillMount()  {
   
        this.props.setProgramm('schoolflashcards ' + version + '.' + date)       

        axios.get('/api/hello')
        .catch(
            error=>{
                browserHistory.push('/htmlerror/' +error.response.status )                
            }
        )

        if (this.props.params.code!==undefined){

            const benutzer={
                    id:0,
                    benutzername:'',
                    namevoll:'',
                    kuerzel:'',
                    anmeldecode: this.props.params.code,
                    ist_main_admin:0,
                    kann_lehrpersonen_bearbeiten:0,
                    email:'',
                    ist_schueler:0,
                }

                this.props.loginUser( benutzer)
                .then(result=>{

                        if (result.payload.data.id > 0){    

                            const sessionUser = {
                                userID:result.payload.data.id.toString()
                            } 
                            axios.post('/api/session',sessionUser)
                            .then(
                                result2=>{
                                }
                            )  

                            if (result.payload.data.ist_main_admin===1){ 
                                browserHistory.push('/admin')
                            }
                            else {
                                browserHistory.push('/user')
                                return;
                            } 
                        }
                        else{
                            this.props.logoutUser()
                            browserHistory.push('/login')
                        }
                    }
                )

        }
        

        axios.get('/api/session')
        .then(            
            result=>{                     
                 if (result.status===200) {      

                            

                    if (this.props.activeUser ===undefined || this.props.activeUser ===null ){

                        browserHistory.push('/login')
                    }                
               }              
            }
        )
        .catch( (error)=>{
                if(error.response.status===401){
                    browserHistory.push('/login')
                }
            }
        )     
    }  

    
    
    
    render() {

        const isProduction = process.env.NODE_ENV === 'production';

        return(
            <>
                <Helmet>       
                    <script>

                    
                    </script>     
                </Helmet>     
            <CacheBuster
                currentVersion={version}
                isEnabled={true} //If false, the library is disabled.
                isVerboseMode={false} //If true, the library writes verbose logs to console.
                loadingComponent={<div>Neue Version checken...</div>} //If not pass, nothing appears at the time of new version check.
            >
               

            <div>{this.props.children}</div>

                   


            </CacheBuster>
            </>
        )
        
    }
}



function mapStateToProps ({ posts }) {
    return {
      schulName: posts.schulName,
      activeUser: posts.userData,
      freischaltung: posts.freischaltung
    };
  };

  export default connect(mapStateToProps, {setProgramm, loginUser, logoutUser}) (App);

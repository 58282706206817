import React from 'react'
import Draggable from 'react-draggable';

const Beschriftung = (props) =>{   
    
    const id = `beschriftung${props.nummer}`
    let  imageSource = `/assets/img/beschriftung_${props.nummer+1}_${props.pfeil}.png`
    if (props.mappingNummer !== undefined){
        imageSource = `/assets/img/beschriftung_${props.mappingNummer}_${props.pfeil}.png`
    }
   

    const url = `url(${imageSource})`
    let controlledPosition={
        x:props.left,
        y:props.top
    };
 
    function stop(e){        
        
        e.preventDefault();
        e.stopPropagation();     
     props.onStopDrag(props.nummer, controlledPosition )  
    }

    function start(){
        if (props.canDrag === false) return false;
        return true;
    }

    function onDrag (e, position) {

        const {x, y} = position;
        controlledPosition = {
            x:props.left + x, 
            y:props.top + y
        };
      //  console.log(controlledPosition)      
      };
    
    
    

    const left = `${props.left}px`
    const top=`${props.top}px` 

    let style = {zIndex:'3000', cursor:'grabbing',position:'absolute',  height:'62px', width:'62px', left:left, top:top, backgroundImage:url}   
    
    if (!props.canShow){
        style = {visibility:'hidden', zIndex:'3000',height:'62px', width:'62px',position:'absolute',  left:left, top:top, backgroundImage:url}
    }

    return(
       
        <Draggable
            position={{x:0, y:0}}
            onDrag={onDrag}
            onStop={stop}
            onStart={start}>        
            <div id={id} style={style}></div>
        </Draggable>
       
    )
}


export default Beschriftung;
import React from 'react'

import 'material-icons/iconfont/material-icons.css';

const SchuelerGruppenPanel =(props) => {   

        if (props.dataset===undefined)    return null 

        let buttonAlle = <div><button className="btn btn-default btn-sm btn-round"  name="allemarkieren" onClick={props.markieren}>alle ja</button> <button className="btn btn-default btn-sm btn-round" name="alledemarkieren" onClick={props.markieren}>alle nein</button></div>
        if (props.showAlle===false)buttonAlle=null

        return(
            <>       
            {props.gruppenHeader}    
                     <h4 className="card-title">{props.headertitle}</h4>  
            
            
                <div className="table-responsive">
                   
                           {buttonAlle}      
                    <table className="table table-striped">  
                                <thead>
                                    <tr>
                                        <th className="text-center"> </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>                             
                        <tbody>
                            {props.dataset.map(props.dialogRow)}
                        </tbody>
                    </table>
                </div>

            </>
        )

}

export default SchuelerGruppenPanel

import React, { Component } from 'react';
import browserHistory from 'react-router/lib/browserHistory';
import axios from 'axios';
import $ from 'jquery';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { loginUser, logoutUser } from '../actions';
import Modal from '../components/modal.jsx'
import {getGUID, getCookie, setCookie, setCookies} from '../components/generalfunctions'
import 'material-icons/iconfont/material-icons.css';



class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            benutzer:null,
            server:null,
            anmeldecode:'',
            anmeldecode_vergessen_email:'',
            errortext:undefined,  
            activeBenutzer:null,
            cookie_speichern:'ja',
            cookie_anmeldecode:'',
            cookie_benutzername:'',
            nur_einzelperson:false,


            benutzerReg:{                       
                benutzername:'',
                namevoll:'',
                kuerzel:'',
                schulName1:'',
                schulStrasse:'',
                schulPLZ:'',
                schulOrt:'',
                schulLand:'DE',            
                email:'',
            }
            
                 
        }

        this.anmelden = this.anmelden.bind(this)
        this.registrieren = this.registrieren.bind(this)
        this.modalNeuRegistrierenDialog = this.modalNeuRegistrierenDialog.bind(this)
        this.aktualisiereNeuenBenutzer = this.aktualisiereNeuenBenutzer.bind(this)
        this.anmeldeCodeVergessenDialog = this.anmeldeCodeVergessenDialog.bind(this)
        this.anmeldecodeschicken = this.anmeldecodeschicken.bind(this)
        this.cookieSpeichern = this.cookieSpeichern.bind(this)
        this.modalDatenschutzerklaerung = this.modalDatenschutzerklaerung.bind(this)
        this.modalImpressum=this.modalImpressum.bind(this)
        this.modalKonzeptKarteikartendialog = this.modalKonzeptKarteikartendialog.bind(this)
      
    }

    modalAnmeldeCodeWurdeVerschicktDialog(){

        return(           

            <Modal
                modalid="modalAnmeldeCodeWurdeVerschicktDialog"
                buttonid="modalAnmeldeCodeWurdeVerschicktDialogButton"                
                title="Schoolflashcards"
                body="Wir haben Ihnen eine Mail mit Ihrem Anmeldecode geschickt! Bitte überprüfen Sie ggf. Ihren Spamordner."  
                
            />
        )

    }
   
    
    componentWillMount(){       

        let cookie_speichern = getCookie('cookie_speichern')
        let cookie_anmeldecode = getCookie('cookie_anmeldecode')
        let cookie_benutzername = getCookie('cookie_benutzername')
        this.setState({cookie_speichern,cookie_anmeldecode, cookie_benutzername, anmeldecode: cookie_anmeldecode })

       
        //Wenn schon über QR-Code eingeloggt
        if (this.props.activeUser !==undefined && this.props.activeUser!==null){

             setCookies(true, this.props.activeUser)

            if (this.props.activeUser .ist_main_admin===1){ 
                browserHistory.push('/admin')
            }
            else{
                browserHistory.push('/user')
            }


        }

        axios.get('/api/hello/server')
        .then(
            result=>{
                this.setState({server:result.data})
            }
        )

        
       
        axios.get('/api/benutzer/login/'+ getGUID())
        .then(
            result=>{                
                if (result.data.id > 0 ){                   
                    this.setState({activeBenutzer:result.data, anmeldecode:result.data.anmeldecode})
                }    
            }
        ) 
    
    }


 
   anmelden(){

        const benutzer={
            id:0,
            benutzername:'',
            namevoll:'',
            kuerzel:'',
            anmeldecode: this.state.anmeldecode,
            ist_main_admin:0,
            kann_lehrpersonen_bearbeiten:0,
            email:'',
            ist_schueler:0,
            create_user_id:0
        }
        this.props.loginUser( benutzer)
        .then(
            result=>{
                
                if (result.payload.data.id > 0){

                    const sessionUser = {
                        userID:result.payload.data.id.toString()
                    } 
                    axios.post('/api/session',sessionUser)
                    .then(
                        result2=>{
                        }
                    )  
                    if (this.state.cookie_speichern==="ja"){
                        setCookies(true, result.payload.data)  
                    }
                    else{
                        setCookies(false, null)                       
                    }


                    this.setState({errortext:undefined})

                    if (result.payload.data.ist_main_admin===1){ 
                        browserHistory.push('/admin')
                    }               
                    else{

                        if (result.payload.data.ist_gesperrt===1){
                            $('#modalGesperrtDialogButton').click()
                        }
                        else{
                            browserHistory.push('/user')
                        }


                        
                    }
                    
                    $('.modal-backdrop').remove()
                

                }
                else{
                    this.setState({errortext:'Dieser Anmeldecode ist leider nicht bekannt.'})
                }
            }
        )      
      
    }

    registrieren(){

        axios.post('/api/benutzer/registrieren', this.state.benutzerReg)
        .then(
            result=>{
                if (result.data==="benutzerschoneingetragen")    {
                    $('#modalBenutzerExistiertBereitsDialogButton').click()
                    return
                }
                else{

                    const url='https://estrella-software.de/apps/schoolflashcards/erstanmeldecode.php?anmeldecode=' + result.data + '&email=' + this.state.benutzerReg.email + '&benutzername=' + this.state.benutzerReg.benutzername;
                     
                    fetch(url, {
                          mode: 'no-cors'  ,
                        method:'GET'
                        })
                    .then(
                        (response)=>{
                            $('#modalErfolgNeuRegistrierenDialogButton').click()
                        }
                    )                  
                }

            }
        )

        

   

    }

    aktualisiereNeuenBenutzer(e){

        let benutzerReg=this.state.benutzerReg

        if (e.target.name==="benutzername")benutzerReg.benutzername=e.target.value
        if (e.target.name==="namevoll")benutzerReg.namevoll=e.target.value
        if (e.target.name==="kuerzel")benutzerReg.kuerzel=e.target.value
        if (e.target.name==="schulName1")benutzerReg.schulName1=e.target.value
        if (e.target.name==="schulStrasse")benutzerReg.schulStrasse=e.target.value
        if (e.target.name==="schulPLZ")benutzerReg.schulPLZ=e.target.value
        if (e.target.name==="schulOrt")benutzerReg.schulOrt=e.target.value
        if (e.target.name==="schulLand")benutzerReg.schulLand=e.target.value
        if (e.target.name==="email")benutzerReg.email=e.target.value        

        if (e.target.id==="checkDE")benutzerReg.schulLand="DE"
        if (e.target.id==="checkCH")benutzerReg.schulLand="CH"
        if (e.target.id==="checkA")benutzerReg.schulLand="A"

        this.setState({benutzerReg})
    }


    modalNeuRegistrierenDialog(){

        let de_checked = false
        let ch_checked = false;
        let a_checked = false;

        if (this.state.benutzerReg.schulLand==="DE")de_checked = true
        if (this.state.benutzerReg.schulLand==="CH")ch_checked = true
        if (this.state.benutzerReg.schulLand==="A")a_checked = true


        const radioButtons=(
            <>
            <div className="form-check form-check-radio">
                <label className="form-check-label">
                    <input className="form-check-input" type="radio" name="schulLand" id="exampleRadios1" value="DE" checked={de_checked} onChange={this.aktualisiereNeuenBenutzer} />
                    Deutschland
                    <span className="circle">
                        <span className="check"></span>
                    </span>
                </label>
            </div>
            <div className="form-check form-check-radio">
                <label className="form-check-label">
                    <input className="form-check-input" type="radio" name="schulLand" id="exampleRadios2" value="CH" checked={ch_checked} onChange={this.aktualisiereNeuenBenutzer} />
                    Schweiz
                    <span className="circle">
                        <span className="check"></span>
                    </span>
                </label>
            </div>

            <div className="form-check form-check-radio ">
                <label className="form-check-label">
                    <input className="form-check-input" type="radio" name="schulLand" id="exampleRadios3" value="A" checked={a_checked} onChange={this.aktualisiereNeuenBenutzer} />
                    Österreich
                    <span className="circle">
                        <span className="check"></span>
                    </span>
                </label>
            </div>
            </>
        )

        const radios_schuele_oder_einzeln=(
            <>
            <div className="form-check form-check-radio">
                    <label className="form-check-label">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                        Einzelne Person registrieren
                        <span className="circle">
                            <span className="check"></span>
                        </span>
                    </label>
                </div>
                <div className="form-check form-check-radio">
                    <label className="form-check-label">
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" checked={this.state.nur} />
                        Schule registrieren
                        <span className="circle">
                            <span className="check"></span>
                        </span>
                    </label>
                </div>
                </>
        )



        const body=(
            <>
            <div style={{marginBottom:'15px'}}>
                Nach dem Abschicken des Formulars erhalten Sie eine Email mit Ihrem Anmeldecode. 
               <p><b>Bitte füllen Sie alle Felder aus.</b></p>
            </div>

            <div className="table-responsive">
                <table className="table">
                    <tbody>
                        <tr>
                            <td width="200">Vorname und Name</td><td><input className="form-control" autoCapitalize="off" type="text" name="namevoll"  value={this.state.benutzerReg.namevoll} placeholder="Name und Vorname" onChange={this.aktualisiereNeuenBenutzer} /></td>
                        </tr>
                        <tr>
                            <td>Benutzername</td><td><input className="form-control" type="text" autoCapitalize="off" name="benutzername"  value={this.state.benutzerReg.benutzername} placeholder="Benutzername" onChange={this.aktualisiereNeuenBenutzer} /></td>
                        </tr>
                        <tr>
                            <td>Email</td><td><input className="form-control" type="email" autoCapitalize="off" name="email"  value={this.state.benutzerReg.email} placeholder="Email" onChange={this.aktualisiereNeuenBenutzer} /></td>
                        </tr>
                        <tr>
                            <td>Schulname</td><td><input className="form-control" type="text" autoCapitalize="off" name="schulName1"  value={this.state.benutzerReg.schulName1} placeholder="Schulname" onChange={this.aktualisiereNeuenBenutzer} /></td>
                        </tr>
                        <tr>
                            <td>Anschrift</td><td><input className="form-control" type="text" autoCapitalize="off" name="schulStrasse"  value={this.state.benutzerReg.schulStrasse} placeholder="Anschrift" onChange={this.aktualisiereNeuenBenutzer} /></td>
                        </tr>
                        <tr>
                            <td>PLZ/Ort</td><td><input className="form-control" type="text" autoCapitalize="off" name="schulPLZ"  value={this.state.benutzerReg.schulPLZ} placeholder="PLZ" onChange={this.aktualisiereNeuenBenutzer} /><input className="form-control" type="text" name="schulOrt"  value={this.state.benutzerReg.schulOrt} placeholder="Ort" onChange={this.aktualisiereNeuenBenutzer} /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">{radioButtons}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>


            </div>
          

            </>
        )

        let okButtonenabled=  this.state.benutzerReg.namevoll !=="" && this.state.benutzerReg.benutzername!==""  && this.state.benutzerReg.email!=="" && this.state.benutzerReg.schulName1!=="" && this.state.benutzerReg.schulStrasse!=="" && this.state.benutzerReg.schulPLZ !=="" && this.state.benutzerReg.schulOrt !==""



        return(
            <Modal

                modalid="modalNeuRegistrierenDialog"
                buttonid="modalNeuRegistrierenDialogButton"   
                title="Neu registrieren"
                body={body}
                okButtonClick={this.registrieren}  
                okButtonTitle="Registrierung abschicken"
                okButtonenabled={okButtonenabled}


            />
        )

    }

    modalBenutzerExistiertBereitsDialog(){

        return(
            <Modal
                modalid="modalBenutzerExistiertBereitsDialog"
                buttonid="modalBenutzerExistiertBereitsDialogButton"   
                title="Schoolflashcards"
                body="Diese Email-Adresse ist bereits registriert!"     
                />

        )

    }

    modalErfolgNeuRegistrierenDialog(){

        return(
            <Modal
                modalid="modalErfolgNeuRegistrierenDialog"
                buttonid="modalErfolgNeuRegistrierenDialogButton"   
                title="Schoolflashcards"
                body="Vielen Dank für Ihre Anmeldung! Sie erhalten in Kürze eine Email mit Ihrem Anmeldecode. Bitte überprüfen Sie ggf. Ihren Spamordner."  
                okButtonTitle="OK"
            />

        )

    }


    modalGesperrtDialog(){

        return(
            <Modal
                modalid="modalGesperrtDialog"
                buttonid="modalGesperrtDialogButton"   
                title="Schoolflashcards"
                body="Ihr Zugang wurde vorübergehend gesperrt."  
                okButtonTitle="OK"
            />

        )

    }

    anmeldecodeschicken(){

        axios.get(`/api/benutzer/anmeldecodevergessen/${this.state.anmeldecode_vergessen_email}`)
        .then(
            result=>{
                    if (result.data==="diemailadresseexistiertnicht"){
                        $('#modalEmailExistiertNichtDialogButton').click()
                    }
                    else{


                        const url='https://estrella-software.de/apps/schoolflashcards/anmeldecodeschicken.php?anmeldecode=' + result.data + '&email=' + this.state.anmeldecode_vergessen_email;
                    
                        fetch(url, {
                              mode: 'no-cors'  ,
                            method:'GET'
                            })
                        .then(
                            (response)=>{                               
                               // this.setState({showAnmeldeCodeVergessenDialog:false, },)
                                $('#modalAnmeldeCodeWurdeVerschicktDialogButton').click()
                            }
                        ) 

                    }
            }
        )
    }

    modalEmailExistiertNichtDialog(){

        return(
            <Modal
                modalid="modalEmailExistiertNichtDialog"
                buttonid="modalEmailExistiertNichtDialogButton"   
                title="Schoolflashcards"
                body="Diese Emailadresse ist nicht registriert."   
            />
        )

    }

    anmeldeCodeVergessenDialog(){

        const body=(

            <div className="form-group">   
                <label htmlFor="email1">Geben Sie hier bitte Ihre Email-Adresse ein:</label>       
                <input className="form-control" id="email1" type="email"  autoCapitalize="off"  value={this.state.anmeldecode_vergessen_email} placeholder="Email" onChange={(e)=>this.setState({anmeldecode_vergessen_email:e.target.value})} />
            </div>

        )

        let okButtonenabled=this.state.anmeldecode_vergessen_email !==""



        return(
            <Modal
                modalid="anmeldeCodeVergessenDialog"
                buttonid="anmeldeCodeVergessenDialogButton"   
                title="Anmeldecode vergessen"
                body={body}
                okButtonenabled={okButtonenabled}
                okButtonTitle="Abschicken"
                okButtonClick={this.anmeldecodeschicken}

            />
        )

    }

   modalLogindialog(){

    const body=(
        <>
            <div className="form-group">
                <label htmlFor="anmeldeInput">Bitte gib deinen Anmeldecode ein.</label><br/>                          
                <input type="text" className="form-control"  autoCapitalize="off"  value={this.state.anmeldecode} placeholder="Anmeldecode" onChange={(e)=>this.setState({anmeldecode:e.target.value})}/>
                        
            </div>     
            <div className="togglebutton" style={{paddingTop: '25px'}}>
                <label>
                    <input type="checkbox" onChange={this.cookieSpeichern} checked={this.state.cookie_speichern==="ja"}/> <span className="toggle"></span>
                    Anmeldung auf diesem Browser speichern
                </label> 
            </div>
                                                           
            <hr/>                                                
            <a href="#" onClick={()=>$('#anmeldeCodeVergessenDialogButton').click()}>Anmeldecode vergessen?</a>
        </>

    )


    const okButtonenabled = (this.state.anmeldecode   !=="");

      return (
          <Modal 
            modalid="modalLogindialog"
            buttonid="modalLogindialogButton"   
            title="Bei Schoolflashcards anmelden" 
            body={body} 
            canClose={false}
            okButtonTitle="Anmelden"
            okButtonClick={this.anmelden}  
            okButtonenabled={okButtonenabled} 
            errortext={this.state.errortext}/>
      )
   }

   modalVorteileKarteikarten(){
       let body=(
           <div>
               Das Lernen mit Karteikarten hilft dabei, umfangreiche Fachinformationen schnell aufzunehmen und abzuspeichern. Karteikarten erleichtern damit die Prüfungsvorbereitung und führen letztlich zu guten Prüfungsleistungen.

               <h3>Das sind die grössten Vorteile von Karteikarten</h3>
               <ul>
                <li>Einfache Bedienung: Man kann Karteikarten ohne Vorkenntnisse benutzen und schnell zum Lernen vorbereiten.</li>
                <li>Fächerübergreifender Einsatz: Man kann Karteikarten für unterschiedliche Wissensgebiete nutzen.</li>
                <li>Variabler Komplexitätsgrad: Man kann Karteikarten sowohl bei einfachen Informationen als auch bei komplizierten Zusammenhängen einsetzen.</li>
                <li>Konzentriertes Lernen: Man muss beim Beschriften der Karteikarten konkret und präzise sein, weil der Platz eingeschränkt ist – dadurch konzentriert man sich auf die wesentlichen Informationen und lernt fokussierter.</li>
                <li>Freie Gestaltungsmöglichkeiten: Man kann Karteikarten individuell und kreativ gestalten.</li>
                <li>Simuliertes Prüfungsformat: Man kann mit den beidseitig beschrifteten Karteikarten (Frage und Antwort) eine Prüfungssituation simulieren und bereitet sich so optimal auf diese Herausforderung vor.</li>
                <li>Doppelter Lerneffekt: Man kann mit beidseitig beschrifteten Karteikarten in „beide Richtungen“ lernen, je nachdem, welche Seite einer Karteikarte man als Ausgangspunkt nimmt.</li>
               </ul>
               <h4>All diese Vorteile werden bei <i><b>schoolflashcards</b></i> genutzt.</h4>
           </div>
       )

    return( 
    <Modal 
        modalid="modalVorteileKarteikartendialog"
        buttonid="modalVorteileKarteikartendialogButton"   
        title="Vorteile von Karteikarten" 
        body={body} 
        messagebox ={true} 
        />    

    )
   }


   modalKonzeptKarteikartendialog(){

    let body=(
        <div>

            <p><strong>Abstimmung auf schulische Anforderungen</strong></p>
            <p><i>Schoolflashcards</i> ist eine Lernplattform, die aus der Schulpraxis heraus entwickelt wurde und besonders das gemeinsame Lernen im Team fördert. </p>           
                <ul>
                    <li>Karteikartenstapel können allein oder im Team zusammen oder in einzelnen Gruppen bearbeitet werden.</li>
                    <li>Die Stapel können im Team untereinander geteilt und erweitert werden.</li>
                    <li>Die Stapel können im Team gegenseitig zur Überprüfung und Korrektur freigegeben werden.</li>
                    <li>Lehrende haben über die <i>Monitoring-Funktion</i> die Möglichkeit, Lernfortschritte zu beobachten und können verfolgen, wieviel in welcher Zeit gelernt wird.</li>
                </ul>
           
            <p><strong>Spass am Lernen</strong></p>
            <p>Den Schülerinnen und Schülern macht das Erstellen dieser digitalen Karteikarten Spass, da es "kinderleicht" ist und im Handumdrehen viele motivierende und aussagekräftige Karten entstehen können. Nebenbei werden sie mit den gängigen Bearbeitungstechniken aus 
                dem Computerbereich vertraut <small>(z.B. über die Copy & Paste-Funktion beim Einfügen von Bildern aus dem Internet)</small>.</p>
                <p>Die <i>Quiz-Funktion</i> lässt sich sehr gut in wettkampforientierte "Buzzerspiele" im Klassenverbund einbauen. </p>
                <p>Die <i><b>Vokabelkarten-Funktion</b></i> ermöglicht das schnelle Übersetzen von Wörtern in oder aus 22 Sprachen und greift dabei über die PONS-Wörterbuch-API auf die Ressourcen der PONS - Bibliothek zurück.</p>
                <p>Die <i><b>Konjugations-Funktion</b> der Vokabelkarten</i> ermöglicht ein systematisches Lernen in den Sprachen <i>Deutsch, Englisch, Französisch, Spanisch, Portugiesisch und Italienisch</i> mit allen erdenklichen Zeitformen.</p>
            
            <p><strong>Aufnehmen von Featurewünschen</strong></p>
            <p>Registrierte Schulen sind jederzeit aufgerufen, am Konzept dieser Plattform mitzuarbeiten </p>

            <p style={{marginTop:'25px'}}>< a className="text-primary" href="mailto:info@schoolflashcards.eu">Mail an schoolflashcards schreiben</a> </p>
        </div>
    )

    return( 
        <Modal 
            modalid="modalKonzeptKarteikartendialog"
            buttonid="modalKonzeptKarteikartendialogButton"   
            title="Konzept dieser Lernplattform" 
            body={body} 
            messagebox ={true} 
            />    

        )
   }

   cookieSpeichern(e){
       let cookie_speichern="ja"      
       if (!e.target.checked) cookie_speichern='nein'     
       this.setState({cookie_speichern})
       
   }

   modalDatenschutzerklaerung(){

      const body=(
        <div>
            <p><strong>Allgemeiner Hinweis und Pflichtinformationen</strong></p>
            <p><strong>Benennung der verantwortlichen Stelle</strong></p>
            <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
            <p><span id="s3-t-ansprechpartner">Herbert Dapper</span><br/><span id="s3-t-strasse">Ringstrasse 3</span><br/><span id="s3-t-plz">78052</span> <span id="s3-t-ort">Villingen-Schwenningen</span></p><p></p>
            <p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>
            
            <p><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></p>
            <p>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
            
            <p><strong>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</strong></p>
            <p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
            
            <p><strong>Recht auf Datenübertragbarkeit</strong></p>
            <p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
            
            <p><strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong></p>
            <p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>
            
            <p><strong>SSL- bzw. TLS-Verschlüsselung</strong></p>
            <p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>
            
            <p><strong>Registrierung auf dieser Website</strong></p>
            <p>Zur Nutzung bestimmter Funktionen können Sie sich auf unserer Website registrieren. Die übermittelten Daten dienen ausschließlich zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes. Bei der Registrierung abgefragte Pflichtangaben sind vollständig anzugeben. Andernfalls werden wir die Registrierung ablehnen.</p>
            <p>Im Falle wichtiger Änderungen, etwa aus technischen Gründen, informieren wir Sie per E-Mail. Die E-Mail wird an die Adresse versendet, die bei der Registrierung angegeben wurde.</p>
            <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
            <p>Wir speichern die bei der Registrierung erfassten Daten während des Zeitraums, den Sie auf unserer Website registriert sind. Ihren Daten werden gelöscht, sollten Sie Ihre Registrierung aufheben. Gesetzliche Aufbewahrungsfristen bleiben unberührt.</p>
                        
            <p><strong>Cookies</strong></p>
            <p>Unsere Website verwendet ein Cookie, das uns dabei hilft, Sie bei Rückkehr auf unserer Website wiederzuerkennen. Dieses Cookie wird nur gesetzt, wenn Sie die Option "Anmeldung auf diesem Browser speichern" anklicken.</p>
           
            <p>Mit einem modernen Webbrowser können Sie das Setzen dieses Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms von selbst gelöscht werden.</p>
            
            <p><small>Quelle: Datenschutz-Konfigurator von <a href="http://www.mein-datenschutzbeauftragter.de" target="_blank">mein-datenschutzbeauftragter.de</a></small></p>
            </div>
      
      )

      return(
          <Modal 
            modalid="modalDatenschutzerklaerungDialog"
            buttonid="modalDatenschutzerklaerungDialogButton"   
            title="Datenschutzerklärung" 
            body={body} 
            messagebox ={true} 
           />
      )
   }

   modalImpressum(){

    const body=(
        <div>
            <p><span id="s3-t-firma">Herbert Dapper</span><br/>
            <span id="s3-t-strasse">Ringstrasse 3</span><br/>
            <span id="s3-t-plz">D-78052</span> <span id="s3-t-ort">Villingen-Schwenningen</span></p>
            <p><span id="s3-t-telefonr">Tel. +49 (0) 7721 4051670</span><br/>
            <span id="s3-t-email">Email: info@estrella-software.de</span><br/>
            <span id="s3-t-web">Web: www.estrella-software.de</span></p>
       

        </div>
    )

    return(

        <Modal
            modalid="modalImpressumgDialog"
            buttonid="modalImpressumDialogButton"   
            title="Impressum" 
            body={body} 
            messagebox ={true} 
        />
    )
   }

    render()  {

        if (this.state.server==null) return <div></div>

        let buttonAnmelden = <button className="btn btn-primary" onClick={()=>$('#modalLogindialogButton').click()} >Anmelden</button> 

        if (this.state.cookie_benutzername !== ""){
            buttonAnmelden = (
                    <>
                        <button className="btn btn-primary" onClick={this.anmelden}>Anmelden als {this.state.cookie_benutzername}</button> <br/>
                        <a href="#" onClick={()=>$('#modalLogindialogButton').click()}>Mit anderem Code anmelden...</a>
                    </>
            )                          
        }

        return (
            <div className="container">
                <div className="card text-center">  
                    <img src="/assets/img/flashcard_pc.jpg" style={{width:'100%'}} />                        
                                   
                    <div className="card-body" style={{marginTop:'20p'}}>
                        {buttonAnmelden}    
                    </div>
                     <div className="card-footer">
                        <a href="#" onClick={()=>$('#modalNeuRegistrierenDialogButton').click()}>Neu registrieren...</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="card">                            
                            <img className="card-img-top" src="/assets/img/students.jpg" alt="Old School?" />
                                <div className="card-header">
                                    <h3 className="card-title">Voll im Trend</h3>
                                </div>
                            <div className="card-body">
                                <p className="card-text">Karteikartenlernen schien aus der Mode gekommen zu sein. Zu Unrecht, wie viele Lehrpersonen finden. Auch Studentinnen und Studenten haben dieses Lernkonzept neu entdeckt.
                            
                                <br/><a href="#" onClick={()=>$('#modalVorteileKarteikartendialogButton').click()} className="card-link">Mehr über die Vorteile von Karteikarten erfahren</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="card">                           
                            <img className="card-img-top" src="/assets/img/korrigieren.png" alt="Konzept" />
                                <div className="card-header">
                                        <h3 className="card-title">Das Konzept dieser Lernplattform</h3>
                                </div>
                            <div className="card-body">
                                <p className="card-text">Hier können sich Lehrende zusammen mit ihren Lernenden kostenlos registrieren. Die registrierten Personen können eigene Karteikartenstapel erstellen oder die öffentlichen Stapel zum Lernen benutzen.
                                <br/><a href="#" onClick={()=>$('#modalKonzeptKarteikartendialogButton').click()} className="card-link">Was unterscheidet diese Lernplattform von ähnlichen Online-Lernsystemen?</a></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{textAlign: 'center'}}>
                    {this.props.programm} | Server: {this.state.server.version}<br/> <button className="btn btn-default" onClick={()=>$('#modalImpressumDialogButton').click()}>Impressum</button> <button className="btn btn-default" onClick={()=>$('#modalDatenschutzerklaerungDialogButton').click()}>Datenschutzerklärung</button>
                </div>
          
                {this.modalLogindialog()} {this.modalNeuRegistrierenDialog()} {this.modalErfolgNeuRegistrierenDialog()} {this.modalBenutzerExistiertBereitsDialog()} {this.anmeldeCodeVergessenDialog()}
                {this.modalEmailExistiertNichtDialog()} {this.modalAnmeldeCodeWurdeVerschicktDialog()}{this.modalDatenschutzerklaerung()}{this.modalImpressum()}  {this.modalVorteileKarteikarten()}   {this.modalKonzeptKarteikartendialog()}   
              {this.modalGesperrtDialog()}
            </div>


        );
    }
}


function mapStateToProps ({ posts }) {
    return {
      schulName: posts.schulName,
      activeUser: posts.userData,
      programm:posts.programmName,
    };
  };

  
  
  export default reduxForm({
    form: 'LoginForm'
  }) (
    connect(mapStateToProps,{loginUser,  logoutUser})(Login)
  );
  
import React, { Component  } from 'react';
import { Link } from 'react-router';
import axios from 'axios';
import browserHistory from 'react-router/lib/browserHistory';
import { connect } from 'react-redux';
import Modal from '../components/modal.jsx'
import $ from 'jquery';
import parse from 'html-react-parser';
import Karteikarte from './karteikarte.jsx'
import {shuffleArray} from '../components/generalfunctions.jsx'
import 'material-icons/iconfont/material-icons.css';


class PreviewUnterrichtsModul extends Component  {

    constructor(props)  {
        super(props);

       

        this.state=(
          {              
          
            unterrichtsmodul:null,
            unterrichtsmodulteile:[],
            karteikartenIndex:0,
            karteikartenModus:'Frage',
            readonly:false,
            richtigeantworten:0,
            stapelergebnisse:[],
            ergebnisChart:<div></div>,
                
          }
    
        ) 

       this.onClickKarteikarte = this.onClickKarteikarte.bind(this)
       this.getKarteikarte = this.getKarteikarte.bind(this)     
       this.naechsteFrageClick = this.naechsteFrageClick.bind(this)   
       this.vonVorneBeginnen = this.vonVorneBeginnen.bind(this)    
       this.getModulteile =this.getModulteile.bind(this)  
       
        this.setzeStapelZurueck = this.setzeStapelZurueck.bind(this)
        this.modalStapelZuruecksetzen = this.modalStapelZuruecksetzen.bind(this)  
        this.closePreview = this.closePreview.bind(this)
    
    }


    setzeStapelZurueck(){

        const _this = this

        const sql =`DELETE FROM sfc_stapelergebnisse  WHERE unterrichtsmodul_id=${this.state.unterrichtsmodul.id}; UPDATE sfc_unterrichtsmodulteile SET stapelfach_nr=1 WHERE unterrichtsmodule_id=${this.state.unterrichtsmodul.id};`
         const array={sql}

        axios.post('/api/execcommonquery', array)
        .then(
            result=>{

                const link=`/previewunterrichtsmodul/${this.state.unterrichtsmodul.id}/quiz`
               
                this.setState({karteikartenIndex:0}, 
                function(){
                    _this.vonVorneBeginnen()
                })

            }
        )

    }


    modalStapelZuruecksetzen() {

        const body=(
            <div>
              Alle Karten dieses Stapel wieder zurück in Fach 1 legen? Dabei werden alle bisherigen Ergebnisse zurückgesetzt.
            </div>
        )        

        return ( 
            <Modal 
                      modalid="modalStapelZuruecksetzen" 
                      buttonid="modalStapelZuruecksetzenButton" 
                      body={body}
                      title="Zusammenfassung"
                      okButtonTitle="OK"
                      okButtonClick={this.setzeStapelZurueck}
                      okButtonenabled={true}
                      cancelButton={true}                    
                  /> 
        )
   }

    naechsteFrageClick(){
        let karteikartenIndex = this.state.karteikartenIndex;



        if (karteikartenIndex ===-1){
            karteikartenIndex = 0
            this.setState({karteikartenIndex,karteikartenModus:'Frage'})
        }
        else
        if (karteikartenIndex === this.state.unterrichtsmodulteile.length-1){

            if (this.props.params.mode==="edit"){
                this.setState({karteikartenIndex:0,karteikartenModus:'Frage'})
            }
            else{
                 this.setState({karteikartenIndex:-1})
            }
         
           
        }
        else{
            karteikartenIndex = karteikartenIndex + 1
            this.setState({karteikartenIndex,karteikartenModus:'Frage'})
        }        

    }

    getModulteile(){

        axios.get('/api/unterrichtsmodule/byid/' + this.props.params.unterrichtsmodulid)          
        .then(
            result=>{    

                let url = '/api/unterrichtsmodulteile/'+ this.props.params.unterrichtsmodulid
                if (this.props.params.mode==="quiz"){
                    url = '/api/unterrichtsmodulteile/quiz/'+ this.props.params.unterrichtsmodulid
                }

                axios.get(url)
                .then(
                    result2=>{
                                this.setState({
                                    unterrichtsmodul: result.data,
                                    unterrichtsmodulteile:result2.data, 
                                })
                    }
                )
            }             
        )
    


       
    }
    
    componentWillMount(){

       
       this.getModulteile()

        if (this.props.params.param1 !== undefined && this.props.param1==="readonly"){
            this.setState({readonly:true})
        }



    }
    
    onClickKarteikarte(){
        let karteikartenModus=this.state.karteikartenModus
        if (karteikartenModus==='Frage'){
            karteikartenModus='Antwort'
        }
        else{
            karteikartenModus='Frage'
        }
       
        this.setState({karteikartenModus})
      
    }

    messageBox2() {

        const text=<div style={{backgroundColor:"#e7e7e7"}}>Das Ergebnis wurde erfolgreich verschickt!</div>
        return ( 
            <Modal 
                      modalid="messageBox2" 
                      buttonid="messageBoxButton2" 
                      body={text}
                      title="Zusammenfassung"
                      okButtonTitle="OK"
                      okButtonClick={null}
                      okButtonenabled={true}
                      cancelButton={false}                    
                  /> 
        )
   }

   vonVorneBeginnen(){
        this.getModulteile()
        let unterrichtsmodulteile = this.state.unterrichtsmodulteile
        shuffleArray(unterrichtsmodulteile)
        this.setState({karteikartenIndex:0, unterrichtsmodulteile, karteikartenModus:'Frage'})
   }

   
    getKarteikarte(){      

        let quiz = false
        if (this.props.params.mode==="quiz") quiz=true

        return(
            <Karteikarte 
            id="karteikarte"
                title={this.state.unterrichtsmodul.bezeichnung}  
                unterrichtsmodulteile={this.state.unterrichtsmodulteile} 
                unterrichtsmodul={this.state.unterrichtsmodul}
                karteikartenIndex={this.state.karteikartenIndex}
                onClickKarteikarte={this.onClickKarteikarte}
                karteikartenModus={this.state.karteikartenModus}   
                naechsteFrageClick = {this.naechsteFrageClick}
                vonVorneBeginnen={this.vonVorneBeginnen}
                benutzer_id={this.props.activeUser.id}
                quiz={quiz}
            />
        )

    }



    closePreview(){
      
        browserHistory.push('/user/flashcards')
      }


    render(){

        if (this.state.unterrichtsmodul==null) return <div>Am Laden...</div>
     
    
        let closeLink =  <button onClick={this.closePreview} className="btn btn-danger">Schliessen</button>
        let link=""
        if (this.props.params.mode==="briefkasten" || this.props.params.mode==="globalemodule"){
            link = "/editunterrichtsmodul/" + this.props.params.unterrichtsmodulid + '/' + this.props.params.mode
            closeLink =  <Link to={link} className="btn btn-danger">Schliessen</Link>
        }

        let player = document.getElementById('youtubevideo')
        if (player !==null){
           // $('#youtubevideo').remove()
        }
      

        let topCloseLink=<div className="noprint">{closeLink}</div>
        let btnUnterrichtsmodulBearbeiten = null
        let bearbeitenLink
        let titleBearbeitenLink ='Karteikartenstapel bearbeiten'                
        let buttonAllesZuruecksetzen =   buttonAllesZuruecksetzen = <button  className="btn btn-primary" onClick={()=>$('#modalStapelZuruecksetzenButton').click()}> Alles auf Anfang setzen <i className="material-icons"> undo </i></button>

        bearbeitenLink = `/editunterrichtsmodul/${this.state.unterrichtsmodul.id}/preview`
        btnUnterrichtsmodulBearbeiten = <Link className="btn btn-primary align-right" to={bearbeitenLink}>{titleBearbeitenLink}</Link>     

        if (this.props.params.mode==="globalemodule")btnUnterrichtsmodulBearbeiten=null

        if (this.props.activeUser.id !== this.state.unterrichtsmodul.eigentuemer_id){
            btnUnterrichtsmodulBearbeiten = null;
        }
        let buttonKopieren = null
        if (this.props.params.mode ==="briefkasten"){
            const link = `/user/kopieren/${this.state.unterrichtsmodul.id}/flashcards`
            buttonKopieren = <Link to={link}  className="btn btn-primary align-right"><i className="material-icons"> attach_file</i>Zu den eigenen Stapeln kopieren</Link>
        }

        if (this.state.karteikartenIndex===-1){
           //      buttonAllesZuruecksetzen = <button  className="btn btn-primary" onClick={()=>$('#modalStapelZuruecksetzenButton').click()}> Alles auf Anfang setzen <i className="material-icons"> undo </i></button>
   
        }


        let content = null
        let modulTitle = null  
        let subModulTitle = null
        let line = <div className="line noprint"/>  

        if (this.state.unterrichtsmodul !==null){

            modulTitle = <h2>{this.state.unterrichtsmodul.bezeichnung}</h2>
            
            if (this.state.unterrichtsmodulteile.length > 0){
                modulTitle = this.
                line = null;
                topCloseLink = null;
                content = this.getKarteikarte()                
            }
        }
      
        const messageBox2 = this.messageBox2()
      
        return(
            <div className="container">                 
               
                <div className="card">
                {topCloseLink}
                {line}
                {modulTitle}
                {line}                                                        
                {content}
                {line}
                <div className="noprint">{closeLink} {btnUnterrichtsmodulBearbeiten}{buttonKopieren} {buttonAllesZuruecksetzen} </div>     
                </div>  
                {messageBox2}    {this.modalStapelZuruecksetzen()}        
            </div>
        )
    }
}



function mapStateToProps ({ posts }) {
    return {
      activeUser: posts.userData,
      activeSchueler: posts.pupilsData
    };
  };
  export default connect(mapStateToProps) (PreviewUnterrichtsModul);
  
  
import React from 'react';
import browserHistory from 'react-router/lib/browserHistory';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import $ from 'jquery';
import Modal from '../components/modal';
import {convertDate} from '../components/generalfunctions'
import {shuffleArray} from '../components/generalfunctions.jsx'
import { setGlobaleModuleModus } from '../actions';
import 'material-icons/iconfont/material-icons.css';


class GlobaleModule extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
            globalemodule:[],
            flashcardsthemen:[],
            flashcardsthemenukategorien:[],
            activeKategorie:null,
            active_thema_id:0,
            active_kategorie_id:0,
            activeCopyModule:null,
            alertMessage: '',
        }
        this.nav = this.nav.bind(this)
        this.themenTab = this.themenTab.bind(this)
        this.tabPane = this.tabPane.bind(this)
        this.previewModule = this.previewModule.bind(this)
        this.selectThemen = this.selectThemen.bind(this)
        this.copyModule = this.copyModule.bind(this)
        this.alertMessage = this.alertMessage.bind(this)
        this.alertMessageDialog = this.alertMessageDialog.bind(this)
        this.kopieren = this.kopieren.bind(this)
    }

    alertMessageDialog() {
        return (
            <Modal
                modalid="alertMessageDialog"
                buttonid="alertMessageDialogButton"
                title="Schoolflashcards"
                body={this.state.alertMessage}
                okButtonTitle="OK"
                okButtonenabled={true} />
        )
    }



    alertMessage(message) {

        this.setState({ alertMessage: message }, function () { $('#alertMessageDialogButton').click() })

    }

    copyModule(modul){

        this.setState({activeCopyModule:modul},
            function(){
                $('#modalCopyDialogButton').click()
            })
    }

    kopieren(){


        let unterrichtsmodul = this.state.activeCopyModule
        unterrichtsmodul.eigentuemer_id = this.props.activeUser.id
        unterrichtsmodul.ist_kopie_von_umodul_id = this.state.activeCopyModule.id
        unterrichtsmodul.benutzer_ordner_id = this.state.parent_id

        axios.post('/api/unterrichtsmodule/kopieren', unterrichtsmodul)
            .then(
                result2 => {                    
                    this.alertMessage('Der Stapel ' + this.state.activeCopyModule.bezeichnung + ' wurde zu den eigenen Flashcards kopiert.')
                    this.setState({activeCopyModule:null})
                }
            )






    }


    modalCopyDialog(){


        if (this.state.activeCopyModule===null) return null

        const body=(
            <div>
                <b>{this.state.activeCopyModule.bezeichnung}</b> wird zu den eigenen Stapeln kopiert.
            </div>
        )

        return (
            <Modal
                modalid="modalCopyDialog"
                buttonid="modalCopyDialogButton"
                title="Globalen Stapel kopieren"
                body={body}
                okButtonClick={this.kopieren}
                okButtonTitle="Kopieren"
                cancelButton={true}
            />
        )
    }

    componentWillMount(){
        axios.get('/api/unterrichtsmodule/globalemodule')
        .then(
            result=>{
                if (result.data !==null){
                    
                    let flashcardsthemenukategorien = []
                    let flashcardsthemen = []
                    let globalemodule = []
                    let activeKategorie = null
                    if (result.data.flashcardsthemenukategorien !==null){
                        flashcardsthemenukategorien = result.data.flashcardsthemenukategorien
                        //if (flashcardsthemenukategorien.length > 0) activeKategorie=flashcardsthemenukategorien[0]
                    }
                    if (result.data.flashcardsthemen !==null){
                        flashcardsthemen = result.data.flashcardsthemen
                    }
                    if (result.data.globalemodule !==null){
                        globalemodule = result.data.globalemodule
                        
                    }
                    let active_thema_id = 0
                    if (flashcardsthemen.length >0 )active_thema_id = flashcardsthemen[0].id
                    this.setState({globalemodule,flashcardsthemen,flashcardsthemenukategorien, activeKategorie, active_thema_id },
                        function (){
                            this.selectThemen()
                        })
                }
            }
        )


       
        
    }

    selectThemen(){
        if (this.props.globaleModuleModus !==undefined){

            const _this = this
            const kategorie_id = this.props.globaleModuleModus.active_kategorie_id

            this.state.flashcardsthemenukategorien.forEach(
                function(kategorie){
                    if (kategorie.id===kategorie_id ){
                        _this.state.flashcardsthemen.forEach(
                            function(thema){
                                if (thema.id===kategorie.flashcards_themen_id){

                                    _this.setState({active_thema_id:thema.id, active_kategorie_id:kategorie_id})
                                }
                            }
                        )
                    }
                }
            )

        }
    }


    previewModule(modul){
        const globaleModuleModus={
            active_kategorie_id:modul.flashcards_kategorie_id,

        }
        this.props.setGlobaleModuleModus(globaleModuleModus)

        let link = '/editunterrichtsmodul/' + modul.id + '/globalemodule'
        browserHistory.push(link)

    }

    tabPane(thema){  

        const _this = this
        
        let index = this.state.flashcardsthemen.indexOf(thema)
        let classNameNavLink="tab-pane"
        if (thema.id===this.state.active_thema_id)classNameNavLink ="tab-pane active"     
        let id= `thema${thema.id}`

        let kategorien = []
        this.state.flashcardsthemenukategorien.forEach(
            function(kat){
                if (kat.flashcards_themen_id === thema.id)kategorien.push(kat)
            }
        )
        let i=0
        let navPills=[]
        let content = []
        kategorien.forEach(
            function(kat){
                let clName="nav-link"
                let lbName="tab-pane"
                if (_this.state.active_kategorie_id>0)i=_this.state.active_kategorie_id
                if (kat.id===i){clName="nav-link active";lbName="tab-pane active"}
                let href=`#kat${kat.id}`
                let id=`kat${kat.id}`
                navPills.push(

                    <li className="nav-item">
                        <a className={clName} data-bs-toggle="tab" href={href} role="tablist" aria-expanded="true">{kat.kategorie}</a>
                    </li>
                )

                let module = []
                _this.state.globalemodule.forEach(
                    function(modul){
                        if (modul.flashcards_kategorie_id===kat.id){
                            module.push(modul)
                        }
                    }
                )
                let trs = []
                module.forEach(
                    function(modul){
                        let link = '/editunterrichtsmodul/' + modul.id + '/globalemodule'
                        let btnPreview = <button className="btn btn-primary btn-sm btn-round" onClick={() => _this.previewModule(modul)}><i className="material-icons"> pageview </i> Karten / Vorschau </button>
                        let btnKopieren = <button className="btn btn-primary btn-sm btn-round" onClick={() => _this.copyModule(modul)}><i className="material-icons"> attach_file </i> Stapel kopieren </button>
                        trs.push(
                            <tr key={modul.id}>
                              <td>{module.indexOf(modul)+1}</td>
                              <td>{modul.bezeichnung}</td>
                              <td>{modul.anzahl_modulteile} Karten</td>
                              <td>{convertDate(modul.public_datum)}</td>
                              <td>{modul.public_autor_pseudonym}</td>
                              <td>{modul.public_bemerkungen}</td>
                              <td>{btnPreview}</td>
                              <td>{btnKopieren}</td>
                            </tr>
                        )
                    }
                )
                

                content.push(

                    <div className={lbName} id={id} aria-expanded="true">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Titel</th>
                                        <th>Karten</th>
                                        <th>Datum</th>
                                        <th>Autor</th>
                                        <th>Bemerkungen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trs}
                                </tbody>
                            </table>
                        </div>
                    </div>

                )

                i++
            }
        )

        let nav=(
            <ul className="nav nav-pills nav-pills-primary" role="tablist">
              {navPills}
            </ul>
        )

       return (
           <div className={classNameNavLink} id={id}>
           <h2 className="card-title">{thema.bezeichnung}</h2>
                {nav}

                <div className="tab-content tab-space">
                {content}
                </div>


            </div>
       )

    }

    

    themenTab(thema){
        let index = this.state.flashcardsthemen.indexOf(thema)
        let classNameNavLink="nav-link"
        if (thema.id===this.state.active_thema_id)classNameNavLink ="nav-link active"
        let href= `#thema${thema.id}`
        
        return(
            <li key={thema.id} className="nav-item">
                 <a className={classNameNavLink} href={href} data-bs-toggle="tab">{thema.bezeichnung}</a>
             </li>
        )
            
    }

    nav(){

        const backButton = <Link className="btn btn-danger" to="/user/flashcards"><i className="material-icons"> chevron_left </i> Zurück </Link>



        return (
            <div style={{ padding:'50px'}}>
              {backButton}
                <div className="card card-nav-tabs card-plain">
                    <div className="card-header card-header-primary">
                        <div className="nav-tabs-navigation">
                            <div className="nav-tabs-wrapper">
                               
                                   <ul className="nav nav-tabs" data-tabs="tabs">
                                    {this.state.flashcardsthemen.map(this.themenTab)}                            
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card-body ">
                        <div className="card tab-content text-center">
                        {this.state.flashcardsthemen.map(this.tabPane)}  
                        </div>
                    </div>
                </div>
                <hr/>
                {backButton} 
                {this.modalCopyDialog()} {this.alertMessageDialog()}
            </div>
        )
    }

    render(){

        return(
            <div>
            {this.nav()}
              
            </div>
        )
    }

}



function mapStateToProps ({ posts }) {
    return {
      activeUser: posts.userData,
      programm:posts.programmName,
      globaleModuleModus:posts.globalemodulemodus
    };
  };
  
  export default connect(mapStateToProps, {setGlobaleModuleModus}) (GlobaleModule);
  
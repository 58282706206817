import React, { useState} from "react";
import Camera, {FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ImagePreview from './imagepreview.jsx';

const CameraPanel = (props) => {

 //  const [dataUri, setDataUri] = useState('');

  

  function handleTakePhoto (dataUri) {
    console.log('takePhoto');
  //  setDataUri(dataUri);
    props.onTakePhoto(dataUri)
  }

 

  return (
    <div>
      {
        (props.dataUri)
          ? 
          <ImagePreview dataUri={props.dataUri}
            isFullscreen={false}
          />
          : 
          <Camera 
              onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
              isFullscreen={false}
              imageCompression={0.7}
              idealFacingMode={FACING_MODES.ENVIRONMENT}
          />
      }
    </div>
  );
}

export default CameraPanel;
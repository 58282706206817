import React from 'react'
import $ from 'jquery';
import YouTube, { YouTubeProps } from 'react-youtube';

const YoutubeVideo =(props)=>{

    if (props.karteikarte===null) return null

    let player

    function setPlayer(target){
        player = target;
        play()
    }

    function play(){
        player.seekTo(props.karteikarte.youtube_video_startzeit)
        player.playVideo() 
        if (props.karteikarte.youtube_video_laufzeit > 0)
        setTimeout(function(){
            player.pauseVideo()
        },props.karteikarte.youtube_video_laufzeit * 1000)   
    }

    function pause(){
        player.pauseVideo()
    }

    function onReady(e){  
        setPlayer(e.target)      
    }

    let height = 390;
    let width = 640;
    let className=""
    if (props.karteikarte.nur_audio_abspielen===1){            
        height = 2
        width = 2
        className="hiddenplayer"
    }

    let opts = {
        height,
        width,
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    }

    return(          
        <div>            
            <YouTube 
                className={className} 
                videoId={props.karteikarte.youtube_video_id} 
                opts={opts}
                onReady={(e)=>onReady(e)}  
            />
            <button className="btn btn-info btn-fab btn-fab-mini btn-round" onClick={()=>pause()}><i className="material-icons"> pause </i></button> <button className="btn btn-info btn-fab btn-fab-mini btn-round" onClick={()=>play()}><i className="material-icons"> replay </i></button>
        </div>
    )
}

export default YoutubeVideo
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import Router from 'react-router/lib/Router';
import browserHistory from 'react-router/lib/browserHistory';
import { Provider} from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';
import routes from './routes';
import promise from 'redux-promise';




const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(
  promise
))(createStore);

ReactDOM.render(

  <Provider store={createStoreWithMiddleware(reducers)}>
    <Router history = {browserHistory} routes={routes} />
  </Provider>
  , document.getElementById('root')

);
registerServiceWorker();

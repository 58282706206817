import React from 'react'
import $ from 'jquery';

import 'material-icons/iconfont/material-icons.css';


const Modal =(props) => {

  

    let returnDisabled = false

    if (props.returnDisabled){
        returnDisabled = true
    }           

    let applyButton = null     
    
  
    function enter(e){
        if (e.key==="Enter" && props.okButtonenabled ){
            if (returnDisabled===false)
                $('#okButton').click()
        }
    }
    

    let target = `#${props.modalid}`


    let leftButton1 = null
    if (props.leftButton1 !==undefined){
        leftButton1 = props.leftButton1
        
    }
        
    
    let okButton// = null;
    let cancelButton=null;
    if (props.cancelButton) {
        cancelButton  =
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>

        if (props.cancelButtonClick !==undefined){
            cancelButton=<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={props.cancelButtonClick}>Abbrechen</button>  
        }

    }
    if (props.okButtonClick!==undefined && props.okButtonTitle!==undefined){
        okButton= <button id="okButton" type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={props.okButtonClick}>{props.okButtonTitle}</button>   
        if (props.applyButtonClick!==undefined) applyButton = <button type="button" className="btn btn-primary" onClick={props.applyButtonClick}>{props.appyButtonTitle}</button>   
    
        if (props.okButtonenabled===false)  {
            okButton= <button type="button" disabled className="btn btn-primary" >{props.okButtonTitle}</button> 
            if (props.applyButtonClick!==undefined) applyButton = <button type="button" disabled className="btn btn-primary" >{props.appyButtonTitle}</button> 
        }

        if (props.canClose===false){
            okButton= <button id="okButton" type="button" className="btn btn-primary" onClick={props.okButtonClick}>{props.okButtonTitle}</button>   
            if (props.applyButtonClick!==undefined) applyButton =<button id="okButton" type="button" className="btn btn-primary" onClick={props.applyButtonClick}>{props.appyButtonTitle}</button> 
            
            if (props.okButtonenabled===false)  {
                okButton= <button id="okButton" disabled type="button" className="btn btn-primary" >{props.okButtonTitle}</button> 
                if (props.applyButtonClick!==undefined) applyButton = <button disabled type="button" className="btn btn-primary" >{props.appyButtonTitle}</button> 
            }
        }     
    }
    
    if (props.messagebox){
        okButton= <button id="okButton" type="button" className="btn btn-secondary" data-bs-dismiss="modal">OK</button>
        if (props.applyButtonClick!==undefined) applyButton = <button type="button" className="btn btn-secondary" >{props.appyButtonTitle}</button>
    }

    if (props.okButtonenabled){
        okButton= <button id="okButton" type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={props.okButtonClick}>{props.okButtonTitle}</button>  
        if (props.applyButtonClick!==undefined) applyButton =<button type="button" className="btn btn-primary" onClick={props.applyButtonClick}>{props.appyButtonTitle}</button>  

        if (props.canClose===false){
            okButton= <button id="okButton" type="button" className="btn btn-primary" onClick={props.okButtonClick}>{props.okButtonTitle}</button>  
            if (props.applyButtonClick!==undefined) applyButton = <button type="button" className="btn btn-primary" onClick={props.applyButtonClick}>{props.appyButtonTitle}</button>  
        
        }     

    }


    let closeButton =(
        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button> )

    if (props.cancelButtonClick !==undefined){
        closeButton=(
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={props.cancelButtonClick}>
            <span aria-hidden="true">&times;</span>
        </button> )       
    }




    if (props.showNoButtons){
        okButton = null;
        cancelButton = null;
        closeButton = null
        applyButton = null
    }

    if (props.showOKButton===false){
        okButton = null;

    }


    let modalClassName="modal fade bd-example-modal-lg"
    if (props.modalClassName!==undefined ){
        modalClassName = props.modalClassName
    }

    let style = null
    if (props.backgroundImage !==undefined){
        style = {backgroundImage:props.backgroundImage}
    }
 
        
    return (
        <div>
        
                
            <button id={props.buttonid} type="button" className="invisible" data-bs-toggle="modal" data-bs-target={target}/>

            <div onKeyPress={enter}  className={modalClassName} id={props.modalid} tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg"  >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{props.title}</h5>
                                {closeButton}
                            </div>
                            <div className="modal-body" style={style}>
                            {props.body}
                            </div>
                        
                            <div style={{padding:'15px'}}>
                            <p><font color="#ff0000">{props.errortext}</font></p>
                            <div style={{display:'grid', justifyContent:'space-between', gridAutoFlow:'column'}}>                                   
                                   <div>  {cancelButton}{leftButton1} {applyButton} {okButton}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

            
    )
        
        
    
}

export default Modal;
import React, { Component } from 'react';


class Hilfe extends React.Component {

    constructor(props){
        super(props);

        this.state=(
            {
                themenNummer:1,
                hilfethema:'Allgemein'
            }
        )
    }

    componentWillReceiveProps(props) {
    }

    zeigeHilfe(nummer){

        this.setState({themenNummer:nummer})
    }


    render(){

        return (

            <div className="card">
                <div className="row">
                    <div className="col-3">
                        <h3 className="card-title">Hilfethemen</h3>
                        <ul>
                            <li><a className="text-primary" href="#" onClick={()=>this.zeigeHilfe(1)}> Thema 1</a></li>
                            <li><a className="text-primary" href="#" onClick={()=>this.zeigeHilfe(2)}> Thema 2</a></li>
                            <li><a className="text-primary" href="#" onClick={()=>this.zeigeHilfe(3)}> Thema 3</a></li>
                            <li><a className="text-primary" href="#" onClick={()=>this.zeigeHilfe(4)}> Thema 4</a></li>
                            <li><a className="text-primary" href="#" onClick={()=>this.zeigeHilfe(5)}> Thema 5</a></li>
                            <li><a className="text-primary" href="#" onClick={()=>this.zeigeHilfe(6)}> Thema 6</a></li>
                            <li><a className="text-primary" href="#" onClick={()=>this.zeigeHilfe(7)}> Thema 7</a></li>
                        </ul>
                    </div>
                    <div className="col-9">
                        <h3 className="card-header">{this.state.hilfethema} {this.state.themenNummer}</h3>
                        
                    </div>
                </div>
                
            </div>
        )
    }

}


export default Hilfe

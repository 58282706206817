import React, { Component, PureComponent, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {convertDate,convertDateTime, clearString, is_Html,  shuffleArray,  getKonjugationsTable, getSpracheLang} from '../components/generalfunctions.jsx'
import parse from 'html-react-parser';
import axios from 'axios';
import { connect } from 'react-redux';
import $ from 'jquery';
import Modal from '../components/modal.jsx'
import { LineChart, Bar, BarChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import browserHistory from 'react-router/lib/browserHistory';
import 'material-icons/iconfont/material-icons.css';
import YoutubeVideo from '../components/youtubevideo.jsx';
import Beschriftung from '../components/beschriftung.jsx'
import {getAnzahlRichtigeAntworten} from './karteikartenutils.jsx'

class Karteikarte extends Component {


    constructor(props)  {
        super(props);
        let karteikarte = null
        let youTubePlayer=null

        if (props.karteikartenIndex >-1){
            karteikarte = props.unterrichtsmodulteile[props.karteikartenIndex]  
        }   

        var  integerList = [0,1,2,3,4,5,6,7,8,9]
        shuffleArray(integerList)
        
        var  mappingIntegerList = [1,2,3,4,5,6,7,8,9,10]
        shuffleArray(mappingIntegerList)
        
        
        
        this.state=(
            {
            
                karteikarte,
                antwort:'',
                showSweetalert: false,
                showErrorAlert: false,
                antwortArray:this.getAntwortArray(karteikarte),
                radioChecked:false,
                anzahlRichtigeAntworten:0,
                zusammenfassung:'',
                stapelergebnisse:[],
                gewusst:false,
                antwort1_richtig:false,
                antwort2_richtig:false,
                antwort3_richtig:false,
                antwort4_richtig:false,
                youTubePlayer:null   ,
                beschriftung1:'',      
                beschriftung2:'',      
                beschriftung3:'',      
                beschriftung4:'',      
                beschriftung5:'',      
                beschriftung6:'',      
                beschriftung7:'',      
                beschriftung8:'',      
                beschriftung9:'',      
                beschriftung10:'',      
                beschriftung1Nummer:'',      
                beschriftung2Nummer:'',      
                beschriftung3Nummer:'',      
                beschriftung4Nummer:'',      
                beschriftung5Nummer:'',      
                beschriftung6Nummer:'',      
                beschriftung7Nummer:'',      
                beschriftung8Nummer:'',      
                beschriftung9Nummer:'',      
                beschriftung10Nummer:'',     
                integerList ,
                mappingIntegerList,
                
            }
        )
        this.getButtonsOrCheckGroup = this.getButtonsOrCheckGroup.bind(this)
        this.getContent = this.getContent.bind(this)
        this.getCardContent = this.getCardContent.bind(this)
        this.changeAntwort2 = this.changeAntwort2.bind(this)
        this.clickAnsweroption = this.clickAnsweroption.bind(this)
        this.confirmAndNextQuestion = this.confirmAndNextQuestion.bind(this)
        this.quizzStarten = this.quizzStarten.bind(this)
        this.getKarteikarteAntwort = this.getKarteikarteAntwort.bind(this)
        this.clickOk = this.clickOk.bind(this)
        this.habsGewusst = this.habsGewusst.bind(this)
        this.habsNichtGewusst = this.habsNichtGewusst.bind(this)
        this.cancelAndNextQuestion = this.cancelAndNextQuestion.bind(this)
        this.getZusammenfassung = this.getZusammenfassung.bind(this)
        this.clickTopDiv = this.clickTopDiv.bind(this)
        this.clickWahrOderWalsch=this.clickWahrOderWalsch.bind(this)
        this.checkIfFinished = this.checkIfFinished.bind(this)
        this.getChart = this.getChart.bind(this)
        this.checkAntwort = this.checkAntwort.bind(this)
        this.richtigeAntwortenPruefen = this.richtigeAntwortenPruefen.bind(this)
        this.getSkizzenBeschriftungsTabelle = this.getSkizzenBeschriftungsTabelle.bind(this)
        this.checkBeschriftungen = this.checkBeschriftungen.bind(this)

    }


    getChart(chartWidth){

        let data = []

        this.state.stapelergebnisse.slice().reverse().forEach(
            function(ergebnis){              
                data.push({
                    datum: convertDateTime(ergebnis.datum),
                    pos: ergebnis.ergebnis,
                    neg: 100-ergebnis.ergebnis
                })
            }
        )

        class CustomizedLabel extends PureComponent {
            render() {
                const { x, y, stroke, value } = this.props;

                return (
                <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                    {value}
                </text>
                );
            }
        }

        class CustomizedAxisTick extends PureComponent {
            render() {
                const { x, y, stroke, payload } = this.props;

                return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                    {payload.value}
                    </text>
                </g>
                );
            }
        }
       
        return(
            <div className="card">
                    <BarChart
                        width={chartWidth-50}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="datum"  height={80}  tick={<CustomizedAxisTick />}  />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="neg" stackId="a" fill="#8884d8" label={<CustomizedLabel />}/>
                        <Bar dataKey="pos" stackId="a" fill="#82ca9d" />
                    </BarChart>
            </div>
        )
    }

    clickWahrOderWalsch(e){
       
        let antwort = 'Es handelt sich um eine wahre Behauptung.'
        if (this.state.karteikarte.wahroderfalsch===0) antwort = 'Es handelt sich um eine falsche Behauptung.'

        if (Number(e.target.value)===this.state.karteikarte.wahroderfalsch){           
            this.setState({showSweetalert:true,showErrorAlert:false, antwort, radioChecked:true,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false})
        }
        else {
            this.setState({showSweetalert:false,showErrorAlert:true, antwort, radioChecked:true,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false})
        }

    }
    

  

    getZusammenfassung(chartWidth){

        let ergebnis = 100 / this.props.unterrichtsmodulteile.length * this.state.anzahlRichtigeAntworten
        let prozent = ergebnis.toFixed(1)

        return(

            <div>  
                <div className="card"  style={{textAlign:"center", backgroundColor:"white"}}>
                    <h2>Zusammenfassung</h2>                                            
                    <div style={{marginTop:"25px"}} >
                        Richtige Antworten: {this.state.anzahlRichtigeAntworten} von {this.props.unterrichtsmodulteile.length} ({prozent} %) <br/>
                    </div>                   
                </div>  
                {this.getChart(chartWidth)}              
            </div>
        )
        
    }

    habsGewusst(){

        if (this.props.previewMode===true){
            this.props.cancelPreview()
            return
        }



        let anzahlRichtigeAntworten = this.state.anzahlRichtigeAntworten
        anzahlRichtigeAntworten++
        this.setState({gewusst:true})
        this.setState({anzahlRichtigeAntworten}, 
            function(){
                this.checkIfFinished(anzahlRichtigeAntworten)
                this.props.naechsteFrageClick()
        } )

    }

    checkIfFinished(anzahlRichtigeAntworten){


        if (this.props.previewMode===true){
            this.props.cancelPreview()
            return
        }


        if (this.props.quiz){
            if (this.state.gewusst)  {
                 if (this.state.karteikarte.stapelfach_nr===2) {
                    this.state.karteikarte.stapelfach_nr=3
                }
                else if (this.state.karteikarte.stapelfach_nr===1) {
                    this.state.karteikarte.stapelfach_nr=2
                }          

            }
            else{
                  this.state.karteikarte.stapelfach_nr=1
            }

            axios.post('/api/unterrichtsmodulteile', this.state.karteikarte)
            .then(
                result=>{
                  }
            )        

        }


        if (this.props.karteikartenIndex===this.props.unterrichtsmodulteile.length-1){
            let ergebnis = 100 / this.props.unterrichtsmodulteile.length * anzahlRichtigeAntworten
            
            const stapelergebnis={
                id:0,
                unterrichtsmodul_id: this.props.unterrichtsmodul.id,
                benutzer_id: this.props.benutzer_id,
                ergebnis,
                anzahl_karten_insg:this.props.unterrichtsmodulteile.length
            }
                           
            axios.post('/api/stapelergebnisse', stapelergebnis)
            .then(
                result=>{
                    if (result.data !==null)
                    this.setState({stapelergebnisse: result.data})
                }
            )
            const sql=`UPDATE sfc_unterrichtsmodule SET datum_letztesquiz=now() WHERE id=${this.props.unterrichtsmodul.id}`
            const array={sql}
            axios.post('/api/execcommonquery', array)
        }

    }

    habsNichtGewusst(){


        if (this.props.previewMode===true){
            this.props.cancelPreview()
            return
        }


        this.checkIfFinished(this.state.anzahlRichtigeAntworten)
        this.props.naechsteFrageClick()
    }

    quizzStarten(){
        this.setState({anzahlRichtigeAntworten:0}, 
        function (){
            this.props.naechsteFrageClick()    
        })
            
    }

    

   clickAnsweroption(antwort){    

      
        let found = false
        let richtigeantwort = this.getKarteikarteAntwort()

        if (antwort.number === 1 && this.state.karteikarte.antwort1_richtig===1){
                this.setState({showSweetalert:true,showErrorAlert:false, antwort, radioChecked:true,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false})
                found = true
        } 
        if (antwort.number === 2 && this.state.karteikarte.antwort2_richtig===1){
                this.setState({showSweetalert:true,showErrorAlert:false, antwort, radioChecked:true,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false})
                found = true
        } 
        if (antwort.number === 3 && this.state.karteikarte.antwort3_richtig===1){
                this.setState({showSweetalert:true,showErrorAlert:false, antwort, radioChecked:true,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false})
                found = true
        } 
        if (antwort.number === 4 && this.state.karteikarte.antwort4_richtig===1){
                this.setState({showSweetalert:true,showErrorAlert:false, antwort, radioChecked:true,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false})
                found = true
        } 
        
        
        if (found ===false){
            this.setState({showErrorAlert:true,showSweetalert:false, antwort: richtigeantwort, radioChecked:true,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false})
        }

    
          
            
   
    }


    getAntwortArray(karteikarte){

        if (karteikarte===null) return null

        if (karteikarte.multiplechoice===0) return []

        //Zufallsreihenfolgen

        let antwortArray = []

        for (var i=1; i<=karteikarte.anzahl_antworten;i++){
            let antwort = karteikarte.antwort1
            if (i===2) antwort = karteikarte.antwort2
            if (i===3) antwort = karteikarte.antwort3
            if (i===4) antwort = karteikarte.antwort4
            antwortArray.push(
                {
                    name:`antwort${i}`,
                    antwort,
                    number:i
                }
            )
        }
       
        shuffleArray(antwortArray)

        return antwortArray
    }

    componentDidMount(){

        
               
    }

    componentWillReceiveProps(props) {

     
        let karteikarte = null
        if (props.karteikartenIndex >-1){
            karteikarte = props.unterrichtsmodulteile[props.karteikartenIndex]
        }   

        var integerList = this.state.integerList

        shuffleArray(integerList)
        
        var  mappingIntegerList = [1,2,3,4,5,6,7,8,9,10]
        shuffleArray(mappingIntegerList)

        
        this.setState({
            karteikarte, 
            radioChecked:false,   
            showErrorAlert:false,
            showSweetalert:false,
            antwortArray:this.getAntwortArray(karteikarte),   
            gewusst:false,
            antwort1_richtig:false,
            antwort2_richtig:false,
            antwort3_richtig:false,
            antwort4_richtig:false,
            integerList,
            mappingIntegerList,
            beschriftung1:'',      
            beschriftung2:'',      
            beschriftung3:'',      
            beschriftung4:'',      
            beschriftung5:'',      
            beschriftung6:'',      
            beschriftung7:'',      
            beschriftung8:'',      
            beschriftung9:'',      
            beschriftung10:'',      
            beschriftung1Nummer:'',      
            beschriftung2Nummer:'',      
            beschriftung3Nummer:'',      
            beschriftung4Nummer:'',      
            beschriftung5Nummer:'',      
            beschriftung6Nummer:'',      
            beschriftung7Nummer:'',      
            beschriftung8Nummer:'',      
            beschriftung9Nummer:'',      
            beschriftung10Nummer:'',     
        })

     
         

      }

 


     checkAntwort(e, num){

         if (num===1){
             this.setState({antwort1_richtig: e.target.checked})

         }
         if (num===2){
             this.setState({antwort2_richtig: e.target.checked})
         }
         if (num===3){
             this.setState({antwort3_richtig: e.target.checked})
         }

         if (num===4){
             this.setState({antwort4_richtig: e.target.checked})
         }
     }
   

    getButtonsOrCheckGroup(){    

        let radioDivs = []
        const _this = this    

        this.state.antwortArray.forEach(
            function(antwort){

                let div= <div><button className="btn btn-default btn-sm btn-round" style={{marginBottom:"25px",minWidth:'400px' }} onClick={()=>_this.clickAnsweroption(antwort)} ><div style={{fontSize:"1.1rem"}}>{antwort.antwort}</div></button></div>
                
                if (getAnzahlRichtigeAntworten(_this.state.karteikarte)>1){
                     div=(                            
                        <div className="togglebutton">
                            <label style={{fontSize:'1.3rem', color:'black'}}>
                                <input type="checkbox" onChange={(e)=>_this.checkAntwort(e, antwort.number)}/>
                                <span className="toggle"></span>
                                {antwort.antwort}
                            </label>  
                        </div>       
                     )
                 }


                radioDivs.push(                                    
                        div    
                )
            }
        )

        if (getAnzahlRichtigeAntworten(this.state.karteikarte)>1){
            radioDivs = (
                <div style={{display:'flex',justifyContent: 'space-around', marginTop:'50px'}}>
                    {radioDivs}
                </div>
            )
        }


        let btnPruefen = null
        if (getAnzahlRichtigeAntworten(this.state.karteikarte)>1){
            btnPruefen=<><hr/><button className="btn btn-primary" onClick={this.richtigeAntwortenPruefen}>Prüfen</button></>
        }


        return(
            <div style={{width:'100%'}}>
                    {radioDivs}
                    {btnPruefen}
            </div>  
        )
    }

    richtigeAntwortenPruefen(){

        let ohneFehler = true
        if (Number(this.state.antwort1_richtig) !==this.state.karteikarte.antwort1_richtig ) ohneFehler=false
        if (Number(this.state.antwort2_richtig) !==this.state.karteikarte.antwort2_richtig ) ohneFehler=false
     
        if (this.state.karteikarte.anzahl_antworten>2){
            if (Number(this.state.antwort3_richtig) !==this.state.karteikarte.antwort3_richtig ) ohneFehler=false
        }
        if (this.state.karteikarte.anzahl_antworten>3){
            if (Number(this.state.antwort4_richtig) !==this.state.karteikarte.antwort4_richtig ) ohneFehler=false
        }
       

        if (ohneFehler){
            
            this.setState({showSweetalert:true,showErrorAlert:false,  radioChecked:true,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false})
        }
        else{
            this.setState({showSweetalert:false,showErrorAlert:true,  radioChecked:true,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false})
        }

    }

    

    changeAntwort2(e){
        let antwort = clearString(e.target.value)
        this.setState({antwort: e.target.value})
        if (e.target.value===this.state.karteikarte.antwort1){
          //  this.setState({showSweetalert:true}) Kinder wollten das nicht
        }
    }

    clickOk(){

        let antwort = this.getKarteikarteAntwort()

        if (this.state.antwort === antwort){
            this.setState({showSweetalert:true,antwort})
        }
        else{
            this.setState({showErrorAlert:true,antwort})
        }
    }


    getContent(){
        if (this.state.karteikarte===null) return null     
        
        let youTubePlayer = null 
        if (this.state.karteikarte.ist_youtube_video===1){
            youTubePlayer = (
                <YoutubeVideo
                  karteikarte={this.state.karteikarte}
                />
            )  
         }    


         let frage = parse(this.state.karteikarte.frage)
         if (!is_Html(this.state.karteikarte.frage)){
            frage = <h2>{this.state.karteikarte.frage}</h2>
         }

        let content
        if (this.props.karteikartenModus==='Frage'){          

            let subcontent = <h4>(Tappen, um die Antwort zu sehen)</h4>
            
            if (this.state.karteikarte.multiplechoice===1){
                subcontent =this.getButtonsOrCheckGroup()                    
            }
            let imgContent = null
            if (this.state.karteikarte.frage_videobild_url !==""){
                imgContent=<div><img width="50%" className="img-fluid" src={this.state.karteikarte.frage_videobild_url} alt="" /></div>
            }


            if (this.state.karteikarte.mussfrageschriftlichbeantworten===1){
                let button = <button className="btn btn-primary" disabled>Prüfen</button>
                if (this.state.antwort !==""){
                    button = <button className="btn btn-primary" onClick={this.clickOk}>Prüfen</button>
                }
                subcontent = 
                    <div className="table-responsive" style={{paddingLeft:"50px", paddingRight:"50px", backgroundColor:"white"}}>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="form-group" style={{textAlign:"center"}}>
                                            <label htmlFor="inputantwort2">Gib die Antwort sorgfältig ein. Achte auf Gross- und Kleinschreibung.</label>
                                            <input type="text"  autoCapitalize="off" autoComplete="off" onChange={this.changeAntwort2} className="form-control" id="inputantwort2" placeholder="Antwort" value={this.state.antwort} />
                                                                                     
                                             {button}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                            
            }

           
            
            if (this.state.karteikarte.wahroderfalsch > -1){
                frage = <h2 className="card-title">Wahr oder falsch? <br /> <b>{frage}</b></h2> 
                subcontent = (
                    <div>
                        <button value={1} onClick={this.clickWahrOderWalsch} className="btn btn-default btn round">Wahr</button> <button value={0} onClick={this.clickWahrOderWalsch} className="btn btn-default btn round">Falsch</button>
                    </div>
                )
            }

            const number = this.props.karteikartenIndex +1

            let divKommentarMehrereAntworten = null
            if (getAnzahlRichtigeAntworten(this.state.karteikarte)> 1){
                  divKommentarMehrereAntworten = <h5>(Eine Antwort oder mehrere Antworten möglich)</h5>
            }            

            let zusatzKonjugation=null
            if (this.state.karteikarte.ist_konjugation===1){
                frage = null               
                zusatzKonjugation=(
                    <>
                    <h3 className="card-title">{getSpracheLang(this.state.karteikarte.konjug_sprache)} - {this.state.karteikarte.konjug_zeitform}</h3>
                    <h4>Konjugiere das Verb <big><b>{this.state.karteikarte.konjug_verb}</b></big></h4>
                    </>
                )
            }

            if (this.state.karteikarte.ist_vokabelaudio===1){
                subcontent = null
                let button = <button className="btn btn-primary" disabled>Prüfen</button>
                if (this.state.antwort !==""){
                    button = <button className="btn btn-primary" onClick={this.clickOk}>Prüfen</button>
                }
                frage = (
                    <div>
                        <h3>Hör gut zu und schreibe richtig</h3>
                        <iframe src={this.state.karteikarte.frage_videobild_url}></iframe>
                        <hr/>
                        <div className="form-group" style={{textAlign:"center"}}>
                                            <label htmlFor="inputantwort2">Gib die Antwort sorgfältig ein. Achte auf Gross- und Kleinschreibung.</label>
                                            <input type="text"  autoCapitalize="off" autoComplete="off" onChange={this.changeAntwort2} className="form-control" id="inputantwort2" placeholder="Antwort" value={this.state.antwort} />
                                                                                     
                                             {button}
                        </div>
                    </div>
                )
            }
            if (this.state.karteikarte.ist_bildmitbeschriftung!==null && this.state.karteikarte.ist_bildmitbeschriftung===1){
                subcontent = null
                zusatzKonjugation = null
                divKommentarMehrereAntworten = null
                imgContent = null
                youTubePlayer = null
                
                let title = "Bild/Karte beschriften"
                if (frage !=="") title = frage
                frage = (
                    <div>
                        <h3>{title}</h3>
                        {this.getSkizzenBeschriftungsTabelle()}
                        <button className="btn btn-danger" onClick={this.checkBeschriftungen}>Überprüfen</button>
                       
                    </div>
                )
            }



            content= (
                <div style={{textAlign:"center"}}>
                    <div  onClick={this.clickTopDiv}>
                        <h4>Frage {number} / {this.props.unterrichtsmodulteile.length}</h4>
                        {frage}
                        {zusatzKonjugation}
                        {divKommentarMehrereAntworten}
                        {imgContent}
                        {subcontent}  
                    </div>     
                    <div >{youTubePlayer} </div>        
                </div>
            )
        }
        else{  //Wenn Antwort

            let antwort = this.getKarteikarteAntwort() 


            let imgAntwortContent = null
            if (this.state.karteikarte.antwort_videobild_url !==""){
                imgAntwortContent=<div><img width="50%" className="img-fluid" src={this.state.karteikarte.antwort_videobild_url} alt="" /></div>
            }

            const buttons=(
                <div>                      
                        <div style={{marginTop:"25px", width:'100%', textAlign:'center'}}>
                            <button id="iweissesnet" style={{height:"80px", width:"255px", fontSize:"1rem"}} onClick={this.habsNichtGewusst} className="btn btn-danger btn-lg btn-round"><i className="material-icons">clear</i> Ich wusste es nicht. </button>
                            <button id="iweisses" style={{marginLeft:"50px",width:"255px",  height:"80px", fontSize:"1rem"}} onClick={this.habsGewusst} className="btn btn-success btn-lg btn-round"><i className="material-icons">check</i> Ich wusste es! </button>
                        </div>
                </div>
            )          

            content=(
                <>
                    <h3 className="card-title">{parse(this.state.karteikarte.frage)}</h3>
                    <div style={{marginTop:"25px"}}>
                    <h2 className="card-title"><b>{parse(antwort)} </b></h2>    
                     {imgAntwortContent}               
                        <div style={{fontSize:"3rem"}}>{buttons}</div>
                    </div>
                </>
            )

            if (this.state.karteikarte.ist_konjugation===1){               

                content=(
                    <> <div style={{marginTop:"25px"}}>
                    {getKonjugationsTable(this.state.karteikarte)} 
                    <div style={{fontSize:"3rem"}}>{buttons}</div>
                    </div>
                    </>
                )
            }
           
        }

        return content

    }

    checkBeschriftungen(){

        let ohneFehler = true

        let fehler = 0;

        if (this.state.karteikarte.beschriftung_antwort_nummern ===0){

            if (this.state.karteikarte.beschriftung_1 !== "" && this.state.beschriftung1.trim() !== this.state.karteikarte.beschriftung_1.trim() ){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_2 !== "" && this.state.beschriftung2.trim() !== this.state.karteikarte.beschriftung_2.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_3 !== "" && this.state.beschriftung3.trim() !== this.state.karteikarte.beschriftung_3.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_4 !== "" && this.state.beschriftung4.trim() !== this.state.karteikarte.beschriftung_4.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_5 !== "" && this.state.beschriftung5.trim() !== this.state.karteikarte.beschriftung_5.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_6 !== "" && this.state.beschriftung6.trim() !== this.state.karteikarte.beschriftung_6.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_7 !== "" && this.state.beschriftung7.trim() !== this.state.karteikarte.beschriftung_7.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_8 !== "" && this.state.beschriftung8.trim() !== this.state.karteikarte.beschriftung_8.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_9 !== "" && this.state.beschriftung9.trim() !== this.state.karteikarte.beschriftung_9.trim()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_10 !== "" && this.state.beschriftung1.trim() !== this.state.karteikarte.beschriftung_10.trim()){
                fehler = fehler + 1
            }
        }
        else{

            if (this.state.karteikarte.beschriftung_1 !== "" && this.state.beschriftung1Nummer !== this.state.mappingIntegerList[0].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_2 !== "" && this.state.beschriftung2Nummer !== this.state.mappingIntegerList[1].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_3 !== "" && this.state.beschriftung3Nummer !== this.state.mappingIntegerList[2].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_4 !== "" && this.state.beschriftung4Nummer !== this.state.mappingIntegerList[3].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_5 !== "" && this.state.beschriftung5Nummer !== this.state.mappingIntegerList[4].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_6 !== "" && this.state.beschriftung6Nummer !== this.state.mappingIntegerList[5].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_7 !== "" && this.state.beschriftung7Nummer !== this.state.mappingIntegerList[6].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_8 !== "" && this.state.beschriftung8Nummer !== this.state.mappingIntegerList[7].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_9 !== "" && this.state.beschriftung9Nummer !== this.state.mappingIntegerList[8].toString()){
                fehler = fehler + 1
            }
            if (this.state.karteikarte.beschriftung_10 !== "" && this.state.beschriftung10Nummer !== this.state.mappingIntegerList[9].toString()){
                fehler = fehler + 1
            }


        }



        if (fehler === 0){
            
            this.setState({showSweetalert:true,showErrorAlert:false,  radioChecked:true,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false})
        }
        else{
            this.setState({showSweetalert:false,showErrorAlert:true,  radioChecked:true,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false})
        }

    }

    getSkizzenBeschriftungsTabelle(){

        const _this = this

        function setBeschriftung(e,index){
            if (index ===0) _this.setState({beschriftung1: e.target.value})
            if (index ===1) _this.setState({beschriftung2: e.target.value})
            if (index ===2) _this.setState({beschriftung3: e.target.value})
            if (index ===3) _this.setState({beschriftung4: e.target.value})
            if (index ===4) _this.setState({beschriftung5: e.target.value})
            if (index ===5) _this.setState({beschriftung6: e.target.value})
            if (index ===6) _this.setState({beschriftung7: e.target.value})
            if (index ===7) _this.setState({beschriftung8: e.target.value})
            if (index ===8) _this.setState({beschriftung9: e.target.value})
            if (index ===9) _this.setState({beschriftung10: e.target.value})

        }


        function setBeschriftungNummer(e,index){
            if (index ===0) _this.setState({beschriftung1Nummer: e.target.value})
            if (index ===1) _this.setState({beschriftung2Nummer: e.target.value})
            if (index ===2) _this.setState({beschriftung3Nummer: e.target.value})
            if (index ===3) _this.setState({beschriftung4Nummer: e.target.value})
            if (index ===4) _this.setState({beschriftung5Nummer: e.target.value})
            if (index ===5) _this.setState({beschriftung6Nummer: e.target.value})
            if (index ===6) _this.setState({beschriftung7Nummer: e.target.value})
            if (index ===7) _this.setState({beschriftung8Nummer: e.target.value})
            if (index ===8) _this.setState({beschriftung9Nummer: e.target.value})
            if (index ===9) _this.setState({beschriftung10Nummer: e.target.value})

        }

        function getBeschriftung(index){
            if (index ===0) return _this.state.beschriftung1
            if (index ===1) return _this.state.beschriftung2
            if (index ===2) return _this.state.beschriftung3
            if (index ===3) return _this.state.beschriftung4
            if (index ===4) return _this.state.beschriftung5
            if (index ===5) return _this.state.beschriftung6
            if (index ===6) return _this.state.beschriftung7
            if (index ===7) return _this.state.beschriftung8
            if (index ===8) return _this.state.beschriftung9
            if (index ===9) return _this.state.beschriftung10    
        }
        function getBeschriftungNummer(index){
            if (index ===0) return _this.state.beschriftung1Nummer
            if (index ===1) return _this.state.beschriftung2Nummer
            if (index ===2) return _this.state.beschriftung3Nummer
            if (index ===3) return _this.state.beschriftung4Nummer
            if (index ===4) return _this.state.beschriftung5Nummer
            if (index ===5) return _this.state.beschriftung6Nummer
            if (index ===6) return _this.state.beschriftung7Nummer
            if (index ===7) return _this.state.beschriftung8Nummer
            if (index ===8) return _this.state.beschriftung9Nummer
            if (index ===9) return _this.state.beschriftung10Nummer   
        }
        function getPfeilrichtung(index){
            if (index ===0) return _this.state.karteikarte.beschriftung_1_pfeil
            if (index ===1) return _this.state.karteikarte.beschriftung_2_pfeil
            if (index ===2) return _this.state.karteikarte.beschriftung_3_pfeil
            if (index ===3) return _this.state.karteikarte.beschriftung_4_pfeil
            if (index ===4) return _this.state.karteikarte.beschriftung_5_pfeil
            if (index ==5) return _this.state.karteikarte.beschriftung_6_pfeil
            if (index ===6) return _this.state.karteikarte.beschriftung_7_pfeil
            if (index ===7) return _this.state.karteikarte.beschriftung_8_pfeil
            if (index ===8) return _this.state.karteikarte.beschriftung_9_pfeil
            if (index ===9) return _this.state.karteikarte.beschriftung_10_pfeil  

            return 1
        }
        function getCanShow(index){
            if (index===0)return _this.state.karteikarte.beschriftung_1 !==""
            if (index===1)return _this.state.karteikarte.beschriftung_2 !==""
            if (index===2)return _this.state.karteikarte.beschriftung_3 !==""
            if (index===3)return _this.state.karteikarte.beschriftung_4 !==""
            if (index===4)return _this.state.karteikarte.beschriftung_5 !==""
            if (index===5)return _this.state.karteikarte.beschriftung_6 !==""
            if (index===6)return _this.state.karteikarte.beschriftung_7 !==""
            if (index===7)return _this.state.karteikarte.beschriftung_8 !==""
            if (index===8)return _this.state.karteikarte.beschriftung_9 !==""
            if (index===9)return _this.state.karteikarte.beschriftung_10 !==""
        }   
        
        function getBeschriftungPositon(index){
      
            let x = 0
            let y = 0

            if (index===0){x = _this.state.karteikarte.beschriftung_1_x; y = _this.state.karteikarte.beschriftung_1_y}
            if (index===1){x = _this.state.karteikarte.beschriftung_2_x; y = _this.state.karteikarte.beschriftung_2_y}
            if (index===2){x = _this.state.karteikarte.beschriftung_3_x; y = _this.state.karteikarte.beschriftung_3_y}
            if (index===3){x = _this.state.karteikarte.beschriftung_4_x; y = _this.state.karteikarte.beschriftung_4_y}
            if (index===4){x = _this.state.karteikarte.beschriftung_5_x; y = _this.state.karteikarte.beschriftung_5_y}
            if (index===5){x = _this.state.karteikarte.beschriftung_6_x; y = _this.state.karteikarte.beschriftung_6_y}
            if (index===6){x = _this.state.karteikarte.beschriftung_7_x; y = _this.state.karteikarte.beschriftung_7_y}
            if (index===7){x = _this.state.karteikarte.beschriftung_8_x; y = _this.state.karteikarte.beschriftung_8_y}
            if (index===8){x = _this.state.karteikarte.beschriftung_9_x; y = _this.state.karteikarte.beschriftung_9_y}
            if (index===9){x = _this.state.karteikarte.beschriftung_10_x; y = _this.state.karteikarte.beschriftung_10_y}

            return {
                x,
                y
            }
        } 
        
        
        function getKartenBeschriftung(index){
            if (index ===0) return _this.state.karteikarte.beschriftung_1
            if (index ===1) return _this.state.karteikarte.beschriftung_2
            if (index ===2) return _this.state.karteikarte.beschriftung_3
            if (index ===3) return _this.state.karteikarte.beschriftung_4
            if (index ===4) return _this.state.karteikarte.beschriftung_5
            if (index ===5) return _this.state.karteikarte.beschriftung_6
            if (index ===6) return _this.state.karteikarte.beschriftung_7
            if (index ===7) return _this.state.karteikarte.beschriftung_8
            if (index ===8) return _this.state.karteikarte.beschriftung_9
            if (index ===9) return _this.state.karteikarte.beschriftung_10    
        }

        let trBeschriftungsInputs = []
        let beschriftungen = []    

        let offset=0

        let div = document.getElementById("titelBildbeschriftung")
        if (div !==null){
            offset = div.style.offsetHeight
        }

    

        this.state.integerList.forEach(
            function(i){
                if (getCanShow(i))
                beschriftungen.push(
                        <Beschriftung                         
                            pfeil={getPfeilrichtung(i)}
                            nummer={i}  
                            mappingNummer={_this.state.mappingIntegerList[i]}
                            left={getBeschriftungPositon(i).x}
                            top={getBeschriftungPositon(i).y}
                            canDrag={false}
                            canShow={getCanShow(i)} 
                        /> 
                )

                const index = i           

                const placeholder = `Beschriftung ${i+1}`

                if (getCanShow(i)) {

                    let tr = (

                            <tr>
                                <td width={15}>{_this.state.mappingIntegerList[i]}=</td>
                                <td><input className="form-control" type="text"  onChange={(e)=>setBeschriftung(e,index)} value={getBeschriftung(index)} /></td>
                            </tr>
                    )

                    if (_this.state.karteikarte.beschriftung_antwort_nummern===1){
                        tr = (
                            <tr>
                                <td width="100"><input className="form-control" type="number" min={1} max={10} onChange={(e)=>setBeschriftungNummer(e,index)} value={getBeschriftungNummer(index)} /></td>
                                <td width="20" className="text-center">=</td>
                                <td><b>{getKartenBeschriftung(index)}</b></td>
                            </tr>
                        )
                    }


                        trBeschriftungsInputs.push(
                            tr
                        )
                }

            }
        )

    
        


        let beschriftungenTable=(
            <table className="table" style={{zIndex:'3000', cursor:'pointer', backgroundColor:'white'}}>
                <tbody>                        
                        {trBeschriftungsInputs}
                </tbody>
            </table>
                
        )

        let image=(
            <img draggable={false}  src={this.state.karteikarte.frage_videobild_url} width={700} />
        )
  

        if (this.state.karteikarte.frage_videobild_url===""){
            beschriftungenTable = null
            image = null
            beschriftungen = null
        }   


       
        

        return(
            
            <>
            <div  style={{position:'relative', width:'700px',}}>
                {image}            
                {beschriftungen}
            </div>  
                {beschriftungenTable}
             </>   

        )

    }

    getKarteikarteAntwort() {
        if (this.state.karteikarte===null) return null
        let antwort = this.state.karteikarte.antwort1

        if (antwort ===null) return ""


        if (this.state.karteikarte.multiplechoice===1){   

            let lis = [];
            for (var i=1;i<=this.state.karteikarte.anzahl_antworten;i++){   

                if(i===1 && this.state.karteikarte.antwort1_richtig===1 )lis.push(<li><b>{this.state.karteikarte.antwort1}</b></li>)
                if(i===2 && this.state.karteikarte.antwort2_richtig===1 )lis.push(<li><b>{this.state.karteikarte.antwort2}</b></li>)
                if(i===3 && this.state.karteikarte.antwort3_richtig===1 )lis.push(<li><b>{this.state.karteikarte.antwort3}</b></li>)
                if(i===4 && this.state.karteikarte.antwort4_richtig===1 )lis.push(<li><b>{this.state.karteikarte.antwort4}</b></li>)

            }
            return <ul style={{marginTop:'25px', fontSize:'15px'}}>{lis}</ul>           

        }   

       

        try{             
            return antwort
        }  
        catch(err){
            return antwort + "FEHLER IM HTML-CODE!!!"
        }
    }

    getCardContent(){        
       
        const content = this.getContent()
      
  

        return(
            <div  style={{display:"table", width:"100%", height:"100%", minHeight:"400px", cursor: "pointer"}}     >
                <div style={{display:"table-cell", verticalAlign:"middle", textAlign:"center"}}>
                    {content}
                </div>
            </div>     
        )
    }

    confirmAndNextQuestion(){
        if (this.state.showSweetalert){

           if (this.props.previewMode===true){
                this.props.cancelPreview()
                return
            }

            let anzahlRichtigeAntworten = this.state.anzahlRichtigeAntworten
            anzahlRichtigeAntworten++   
            this.setState({gewusst:true})

            this.setState({ showSweetalert: false, antwort:'',anzahlRichtigeAntworten,radioChecked:false,antwort1_richtig:false,antwort2_richtig:false, antwort3_richtig:false,antwort4_richtig:false },
                function(){
                    this.checkIfFinished(anzahlRichtigeAntworten)
                    this.props.naechsteFrageClick()
                })


        }
    }

    cancelAndNextQuestion(){
        if (this.state.showErrorAlert){

            if (this.props.previewMode===true){
                this.props.cancelPreview()
                return
            }


            this.checkIfFinished(this.state.anzahlRichtigeAntworten)
            this.props.naechsteFrageClick()
            this.setState({
                showErrorAlert:false, 
                antwort:'',
                radioChecked:false,
                antwort1_richtig:false,
                antwort2_richtig:false,
                antwort3_richtig:false,
                antwort4_richtig:false, })
        }
    }

    clickTopDiv(){
        if (this.state.karteikarte!==null && this.props.karteikartenModus==='Frage' && this.state.karteikarte.mussfrageschriftlichbeantworten!==1 && this.state.karteikarte.wahroderfalsch==-1 && this.state.karteikarte.multiplechoice===0 && this.state.karteikarte.ist_vokabelaudio===0 && this.state.karteikarte.ist_bildmitbeschriftung===0){

            

            this.props.onClickKarteikarte()
        }     
    }

    componentWillUnmount(){

      

    }



   
    render(){

        
        

        let cardcontent = this.getCardContent()

        let btnCaption= 'Zeige Frage'
        let className="card divkarteikarte2  grey-shadow"
        if (this.props.karteikartenModus==='Frage'){
            className="card divkarteikarte  grey-shadow"
            btnCaption='Zeige Antwort'
        }

        let antwort = <div>Die richtige Antwort lautet: {this.getKarteikarteAntwort()}</div>       
         if (getAnzahlRichtigeAntworten(this.state.karteikarte)>1 && this.state.karteikarte.multiplechoice===1){
            antwort = <div>Die richtigen Antworten lauten:<br/> {this.getKarteikarteAntwort()}</div>  
        }
        if (this.state.karteikarte!== null && this.state.karteikarte.ist_bildmitbeschriftung===1){

            let antworten = []
            if (this.state.karteikarte.beschriftung_1 !==""){
                antworten.push(<>{Number(this.state.mappingIntegerList[0])}={this.state.karteikarte.beschriftung_1}<br/></>)
            }
            if (this.state.karteikarte.beschriftung_2 !==""){
                antworten.push(<>{Number(this.state.mappingIntegerList[1])}={this.state.karteikarte.beschriftung_2}<br/></>)
            }
            if (this.state.karteikarte.beschriftung_3 !==""){
                antworten.push(<>{Number(this.state.mappingIntegerList[2])}={this.state.karteikarte.beschriftung_3}<br/></>)
            }
            if (this.state.karteikarte.beschriftung_4 !==""){
                antworten.push(<>{Number(this.state.mappingIntegerList[3])}={this.state.karteikarte.beschriftung_4}<br/></>)
            }
            if (this.state.karteikarte.beschriftung_5 !==""){
                antworten.push(<>{Number(this.state.mappingIntegerList[4])}={this.state.karteikarte.beschriftung_5}<br/></>)
            }
            if (this.state.karteikarte.beschriftung_6 !==""){
                antworten.push(<>{Number(this.state.mappingIntegerList[5])}={this.state.karteikarte.beschriftung_6}<br/></>)
            }
            if (this.state.karteikarte.beschriftung_7 !==""){
                antworten.push(<>{Number(this.state.mappingIntegerList[6])}={this.state.karteikarte.beschriftung_7}<br/></>)
            }
            if (this.state.karteikarte.beschriftung_8 !==""){
                antworten.push(<>{Number(this.state.mappingIntegerList[7])}={this.state.karteikarte.beschriftung_8}<br/></>)
            }
            if (this.state.karteikarte.beschriftung_9 !==""){
                antworten.push(<>{Number(this.state.mappingIntegerList[8])}={this.state.karteikarte.beschriftung_9}<br/></>)
            }
            if (this.state.karteikarte.beschriftung_10 !==""){
                antworten.push(<>{Number(this.state.mappingIntegerList[9])}={this.state.karteikarte.beschriftung_10}<br/></>)
            }
            antwort = (
                <div>
                    Die richtigen Antworten lauten:<br/>
                    {antworten}
                </div>
            )
        }

        if (this.state.karteikarte !==null && this.state.karteikarte.wahroderfalsch>-1)antwort=this.state.antwort

        let sweetAlert = (
            <SweetAlert
                style={{color:'black'}}
                success
                title="Richtig!"  
                onConfirm={()=> this.confirmAndNextQuestion()}
                onCancel={()=> this.confirmAndNextQuestion()}
                show={this.state.showSweetalert}  
                confirmBtnText="Nächste Frage"             
                >
                {antwort}
                </SweetAlert>
        )
       
        if (this.state.karteikarte !==null && this.state.karteikarte.wahroderfalsch>-1)antwort=this.state.antwort

     
        let errorAlert = (
            <SweetAlert
                style={{color:'black'}}
                error
                title="Falsch!"  
                onConfirm={()=> this.cancelAndNextQuestion()}
                onCancel={()=> this.cancelAndNextQuestion()}
                show={this.state.showErrorAlert}    
                confirmBtnText="Nächste Frage"             
                >
                {antwort}
                </SweetAlert>

        )

        let buttonNaechsteFrage = <button  className="btn btn-primary btn-round" onClick={this.props.naechsteFrageClick}>Nächste Frage <i className="material-icons">play_arrow</i></button>
        let buttonAntwort = <button  className="btn btn-info btn-round" onClick={this.props.onClickKarteikarte} >{btnCaption}</button>    
        let buttonErledigt=  null
        if (this.props.karteikartenModus==='Antwort')buttonAntwort = null

        if (this.state.karteikarte!==null && this.props.karteikartenModus==='Frage'){
            if (this.state.karteikarte.mussfrageschriftlichbeantworten===1 && this.state.antwort==="")buttonAntwort = null
            if (this.state.karteikarte.multiplechoice===1 && this.state.radioChecked===false)buttonAntwort = null
            if (this.state.karteikarte.mussfrageschriftlichbeantworten===1)buttonAntwort = null
            if (this.state.karteikarte.wahroderfalsch>-1 && this.state.radioChecked===false)buttonAntwort = null
        }       
        
        let buttonVonVorneBeginnen = null
        
     
        
        if (this.props.karteikartenIndex===-1){
            buttonNaechsteFrage = <button  className="btn btn-primary btn-round" onClick={this.quizzStarten}>Erneut abfragen <i className="material-icons"> play_arrow </i></button>
                 buttonAntwort = null
            let div=document.getElementById('karteikarte')

            if (div !==null ){
                cardcontent = (
                    <>
                      {this.getZusammenfassung(div.offsetWidth)}                  
                      
                    </>
                )

            }
          

            buttonVonVorneBeginnen = <button onClick={()=>this.setState({anzahlRichtigeAntworten:0, ergebnisBereitsGesendet:false}, function(){this.props.vonVorneBeginnen()})  } className="btn btn-primary">quiz wiederholen</button>
        }

        buttonAntwort = null

        buttonNaechsteFrage = null

        let fachnummer_text = null
        if (this.state.karteikarte !==null)fachnummer_text=`Fach ${this.state.karteikarte.stapelfach_nr}`

        let quiztext = null
        if (this.props.quiz) quiztext = "(Quiz)"
       

        let divButtons =<div  style={{marginRight:"20px",marginBottom:"20px", textAlign:'center'}}>{buttonAntwort} {buttonNaechsteFrage}{buttonVonVorneBeginnen}</div>


       
        let previewInfo = null
        if (this.props.previewMode===true){
            previewInfo = "Einzelkarte - Vorschau"
            fachnummer_text = null
        }


        let fach1_classname="btn btn-default"
        let fontsizeFach1 = '1.0rem'
        if (this.state.karteikarte !==null && this.state.karteikarte.stapelfach_nr===1){
            fach1_classname = "btn btn-rose"
            fontsizeFach1 = '2.0rem'
        }
        
        let fach2_classname="btn btn-default"
        let fontsizeFach2 = '1.0rem'
        if (this.state.karteikarte !==null && this.state.karteikarte.stapelfach_nr===2){
            fach2_classname = "btn btn-rose"
            fontsizeFach2 = '2.0rem'
        }

        let fach3_classname="btn btn-default"
        let fontsizeFach3 = '1.0rem'
        if (this.state.karteikarte !==null && this.state.karteikarte.stapelfach_nr===3){
            fach3_classname = "btn btn-rose"
            fontsizeFach3 = '2.0rem'
        }

        let divFach1 = <div className={fach1_classname}  style={{fontSize:fontsizeFach1, width:'33%'}}>{this.props.unterrichtsmodul.anzahlkarten_fach1}</div>
        let divFach2 = <div className={fach2_classname}  style={{fontSize:fontsizeFach2, width:'33%'}}>{this.props.unterrichtsmodul.anzahlkarten_fach2}</div>
        let divFach3 = <div className={fach3_classname}  style={{fontSize:fontsizeFach3, width:'33%'}}>{this.props.unterrichtsmodul.anzahlkarten_fach3}</div>

        let kasten =(
         <div  style={{backgroundColor:'white', border:'1px solid black',padding:'2px',  display:'flex', height:'50px', width:'300px', float:'right'}}>
             {divFach1}
             {divFach2}
             {divFach3}
         </div>
        )

        if (this.props.quiz===undefined ||  this.props.previewMode || this.props.quiz===false  ) kasten = null

        
        
            
        return(
          
           
            <div className={className}>                   
                
             
                <div className="card-header card-header-text card-header-primary">
                     <div>{kasten}</div>
                    <div className="card-text">
                        <h4 className="card-title">{this.props.unterrichtsmodul.bezeichnung} {quiztext} {fachnummer_text} {previewInfo}</h4>
                              
                    </div>
                        
                </div>
                <div  style={{textAlign:"center", marginBottom:"10px"}}>                
                    <div style={{padding:'10px'}} id="karteikarte" > 
                   
                        {cardcontent}                          
                        {divButtons}
                    </div>                    
                </div>
                {sweetAlert}{errorAlert} 
            </div>
                   
    
           
    
        )

    }
}



function mapStateToProps({ posts }) {
    return {
        activeUser: posts.userData,
    };
};

export default connect(mapStateToProps, )(Karteikarte);



import React from 'react'
import { Link } from 'react-router';
import { connect } from 'react-redux';


class HtmlError extends React.Component{


        

    render(){
        let error = null
        if (this.props.params.errno==="500"){
            error = <p>Der Server antwortet nicht.</p>
        }
        return (
            <div className="container container-dialog-card-less-padding">
            <p>{this.props.programm}</p>
                <h3>HTML-Fehler {this.props.params.errno}:</h3>
                {error}
                <Link to="/" className="btn btn-primary">Erneut versuchen</Link>
            </div>
        )
    }
}




function mapStateToProps ({ posts }) {
    return {
      programm:posts.programmName,
    };
  };


export default connect(mapStateToProps) (HtmlError);

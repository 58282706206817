import React from 'react'

import 'material-icons/iconfont/material-icons.css';

const KorrekturStatus =(props) => {   

    let textOK= "Korrigiert und ok!"
    if (props.status==="ok"  && props.text!==undefined) textOK=props.text
    let textFalse= "Bitte verbessern!"
    if (props.status==="false"  && props.text!==undefined) textFalse=props.text

    if (props.status==="ok")
    return (
        <div style={{backgroundColor:'#3fb464', fontFamily:'sans-serif', fontWeight:'bolder', textAlign:'center', color:'white', padding:'2px', borderRadius:'5px' }}>{textOK}</div>
    )
    if (props.status==="false")
    return (
        <div style={{backgroundColor:'#f01f0f', fontFamily:'sans-serif',  fontWeight:'bolder', textAlign:'center',color:'white', padding:'2px', borderRadius:'5px' }}>{textFalse}</div>
    )



    return <></>

}

export default KorrekturStatus
import {FETCH_POST,LOGIN_USER,LOGOUT_USER, GET_SCHULNAME,SET_PROGRAMM, GET_FREISCHALTUNG, SET_ACTIVE_SCHUELER, SET_ACTIVE_SCHULE, SET_GLOBALEMODULE_MODUS } from '../actions/index';

//const INITIAL_STATE = {all: [], post: null};



export default function(state = {}, action)  {
  switch(action.type) {
    case FETCH_POST:
      return {...state, [action.payload.data.id]: action.payload.data};    
    case LOGIN_USER:
      return {...state, userData: action.payload.data}
    case LOGOUT_USER:
      return {...state, userData: null, pupilsData:null}
    case GET_SCHULNAME:
      return {...state, schulName: action.payload.data}
    case SET_PROGRAMM:
      return {...state, programmName: action.param}
    case GET_FREISCHALTUNG:
      return {...state, freischaltung: action.payload.data}
    case SET_ACTIVE_SCHUELER:
      return {...state, schueler: action.param}
    case SET_ACTIVE_SCHULE:
      return {...state, schule: action.param}
    case SET_GLOBALEMODULE_MODUS:
      return {...state, globalemodulemodus: action.param}
        
    default:
      return state;
  }
}

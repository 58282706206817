import React from 'react'


const CardWithText = (props) =>{
    
    
    let header=
        <div className="card-header card-text">
                    <h3 className="card-title">{props.title}</h3>
                    <p className="category">{props.subtitle}</p>
        </div>
                 
    let dropdown = null;
    if (props.dropdown){
        dropdown = props.dropdown
    }
    let subtitle = null
    if (props.subtitle){
        subtitle = <p className="category">{props.subtitle}</p>
    }

    let badge = null
    if (props.badgeCount && props.badgeCount > 0){
        badge = <><i className="material-icons">notifications</i><span className="badge badge-danger">{props.badgeCount}</span></>
    }

    
    
    if (props.stylename)    {
            header=
                <div className={props.stylename}>
                <div className="card-text">
                  <h3 className="card-title">{props.title} {badge} </h3>
                  {subtitle}
                 {dropdown}
                </div>
                </div>
    }   

    let bodyClassName= "card-body"
    if (props.bodyClassName !==undefined) bodyClassName = props.bodyClassName

    return(  

        <div className="card">           
            {header}
            <div id={props.id} className={bodyClassName}>
            {props.body}
            </div>            
        </div>
    )
    
    
}

export default CardWithText
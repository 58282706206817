import React from 'react'


const SchulePanel =(props) => {   

      if (props.activeSchule===undefined)    return null 

   
        let de_checked = false
        let ch_checked = false;
        let a_checked = false;

        if (props.activeSchule.land==="DE")de_checked = true
        if (props.activeSchule.land==="CH")ch_checked = true
        if (props.activeSchule.land==="A")a_checked = true

      

        const radioButtons=(
            <>
            <div className="form-check form-check-radio">
                <label className="form-check-label">
                    <input className="form-check-input" type="radio" name="land" id="exampleRadios1" value="DE" checked={de_checked} onChange={props.aktualisiereSchule} />
                    Deutschland
                    <span className="circle">
                        <span className="check"></span>
                    </span>
                </label>
            </div>
            <div className="form-check form-check-radio">
                <label className="form-check-label">
                    <input className="form-check-input" type="radio" name="land" id="exampleRadios2" value="CH" checked={ch_checked} onChange={props.aktualisiereSchule} />
                    Schweiz
                    <span className="circle">
                        <span className="check"></span>
                    </span>
                </label>
            </div>

            <div className="form-check form-check-radio ">
                <label className="form-check-label">
                    <input className="form-check-input" type="radio" name="land" id="exampleRadios3" value="A" checked={a_checked} onChange={props.aktualisiereSchule} />
                    Österreich
                    <span className="circle">
                        <span className="check"></span>
                    </span>
                </label>
            </div>
            </>
        )

        if (props.activeSchule.name1===null) props.activeSchule.name1=""
        if (props.activeSchule.anschrift1===null) props.activeSchule.anschrift1=""
        if (props.activeSchule.anschrift2===null) props.activeSchule.anschrift2=""
        if (props.activeSchule.plz===null) props.activeSchule.plz=""
        if (props.activeSchule.ort===null) props.activeSchule.ort=""

         return(
            <>
              <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td width="120">Schulname 1</td><td colSpan="2"><input value={props.activeSchule.name1} className="form-control" name="name1"  type="text" placeholder="Schulname Zeile 1" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                            <tr>
                                <td>Schulname 2</td><td colSpan="2"><input value={props.activeSchule.name2} className="form-control" name="name2"  type="text" placeholder="Schulname Zeile 2" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                            <tr>
                                <td>Anschrift 1</td><td colSpan="2"><input value={props.activeSchule.anschrift1} className="form-control" name="anschrift1"  type="text" placeholder="Anschrift 1" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                            <tr>
                                <td>Anschrift 2</td><td colSpan="2"><input value={props.activeSchule.anschrift2} className="form-control" name="anschrift2"  type="text" placeholder="Anschrift 2" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                            <tr>
                                <td>PLZ/Ort</td><td><input value={props.activeSchule.plz}  className="form-control" name="plz"  maxLength="10"  type="text" placeholder="PLZ" onChange={props.aktualisiereSchule} /></td><td><input value={props.activeSchule.ort} className="form-control"  name="ort"  type="text" placeholder="Ort" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                            <tr>
                                <td colSpan="3">
                                   {radioButtons}
                                </td>                               
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td width="120">Telefon 1</td><td><input value={props.activeSchule.telefon1} name="telefon1"  className="form-control"  type="text" placeholder="Telefon 1" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                            <tr>
                                <td>Email 1</td><td><input  value={props.activeSchule.email1} name="email1"  className="form-control"  type="email" placeholder="Email 1" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                            <tr>
                                <td>Telefon 2</td><td><input value={props.activeSchule.telefon2} name="telefon2"  className="form-control"  type="text" placeholder="Telefon 2" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                            <tr>
                                <td>Email 2</td><td> <input  value={props.activeSchule.email2} name="email2"  className="form-control"  type="email" placeholder="Email 2" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                
                <div className="table-responsive">
                    <h4 className="card-title">Rechnungsanschrift</h4>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td width="120">Anschrift 1</td><td colSpan="2"><input value={props.activeSchule.rechnungsanschrift1} className="form-control" name="rechnungsanschrift1"  type="text" placeholder="Anschrift 1" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                            <tr>
                                <td>Anschrift 2</td><td colSpan="2"><input value={props.activeSchule.rechnungsanschrift2} className="form-control" name="rechnungsanschrift2"  type="text" placeholder="Anschrift 2" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                            <tr>
                                <td>PLZ/Ort</td><td><input value={props.activeSchule.rechnungsplz} maxLength="10" className="form-control" name="rechnungsplz" type="text" placeholder="PLZ" onChange={props.aktualisiereSchule} /></td><td><input value={props.activeSchule.rechnungsort} className="form-control"  name="rechnungsort"  type="text" placeholder="Ort" onChange={props.aktualisiereSchule} /></td>
                            </tr>
                            <tr>
                                <td colSpan="3">
                                   
                                </td>                               
                            </tr>
                        </tbody>
                    </table>
                </div>               

                

            </>
        )

}

export default SchulePanel
import React from 'react';
import {Route, IndexRoute} from 'react-router';
import App from './pages/app.jsx';
import Login from'./pages/login.jsx'
import HtmlError from'./pages/htmlerror.jsx'
import Admin from'./pages/admin.jsx'
import User from'./pages/user.jsx'
import EditUnterrichtsModul from './pages/editunterrichtsmodul.jsx'
import PreviewUnterrichtsModul from './pages/previewunterrichtsmodul.jsx'
import GlobaleModule from './pages/globalemodule.jsx'
import Pruefungen from './pages/pruefungen.jsx'
import Wettbewerb from './pages/wettbewerbe.jsx'

export default (
  <>
  <Route path="/" component={App}>
    <IndexRoute component={App}/>
    <Route path="login" component={Login} />
    <Route path="htmlerror/:errno" component={HtmlError} />
    <Route path="admin" component={Admin} />
    <Route path="user" component={User} />    
    <Route path="user/:from" component={User} />     
    <Route path="user/:action/:unterrichtsmodulid/:from" component={User} />   
    <Route path="editunterrichtsmodul/:unterrichtsmodulid" component={EditUnterrichtsModul} />
    <Route path="editunterrichtsmodul/:unterrichtsmodulid/:from" component={EditUnterrichtsModul} />
    <Route path="previewunterrichtsmodul/:unterrichtsmodulid" component={PreviewUnterrichtsModul} />
    <Route path="previewunterrichtsmodul/:unterrichtsmodulid/:mode" component={PreviewUnterrichtsModul} />
    <Route path="globalemodule" component={GlobaleModule} />
    <Route path="pruefungen/:pruefungid" component={Pruefungen} />
    <Route path="wettbewerbe/:wettbewerbid" component={Wettbewerb} />
  </Route>
  
  <Route path="/:code" component={App}>
  </Route>
  </>

);

import parse from 'html-react-parser';
import React from 'react';
import $ from 'jquery';


export function clearString(value){

        return value.replace("\\", "/")
    
    }


export function getOS() {
        var uA = navigator.userAgent || navigator.vendor || window.opera;
        if ((/iPad|iPhone|iPod/.test(uA) && !window.MSStream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';
      
        var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
        for (i = 0; i < os.length; i++) if (new RegExp(os[i],'i').test(uA)) return os[i];
    }




export function is_Html (str){
                return /^<.*?>$/.test(str) && !!$(str)[0];
 }



export function timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);

        if (rhours <10) rhours = "0" + rhours;
        if (rminutes<10)rminutes= "0" + rminutes;
        return rhours + ":" + rminutes;
        }

export function getSpracheLang(value){
        let sprache="Deutsch"
        if (value==="en") sprache="Englisch"
        if (value==="fr") sprache="Französisch"
        if (value==="es") sprache="Spanisch"
        if (value==="it") sprache="Italienisch"
        if (value==="pt") sprache="Portugiesisch"
        return sprache
}

export function getKonjugationsTable(karteikarte){

        let ich_form=getPronom('ich', karteikarte.konjug_sprache)
                let du_form=getPronom('du', karteikarte.konjug_sprache)
                let ersiees_form=getPronom('ersiees', karteikarte.konjug_sprache)
                let wir_form=getPronom('wir', karteikarte.konjug_sprache)
                let ihr_form=getPronom('ihr', karteikarte.konjug_sprache)
                let sie_form=getPronom('sie', karteikarte.konjug_sprache)

      return  <div className="table-responsive">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <td style={{backgroundColor:'#e7e7e7', width:'33%'}}>{ich_form}</td><td style={{backgroundColor:'white'}}><b>{karteikarte.konjug_ich}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor:'#e7e7e7'}}>{du_form}</td><td style={{backgroundColor:'white'}}><b>{karteikarte.konjug_du}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor:'#e7e7e7'}}>{ersiees_form}</td><td style={{backgroundColor:'white'}}><b>{karteikarte.konjug_ersiees}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor:'#e7e7e7'}}>{wir_form}</td><td style={{backgroundColor:'white'}}><b>{karteikarte.konjug_wir}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor:'#e7e7e7'}}>{ihr_form}</td><td style={{backgroundColor:'white'}}><b>{karteikarte.konjug_ihr}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor:'#e7e7e7'}}>{sie_form}</td><td style={{backgroundColor:'white'}}><b>{karteikarte.konjug_sie}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
}





export function getPronom(prom, sprache){
        let result = ""
        if (sprache==="de"){
            if (prom==="ich") result = "ich"
            if (prom==="du") result = "du"
            if (prom==="ersiees") result = "er/sie/es"
            if (prom==="wir") result = "wir"
            if (prom==="ihr") result = "ihr"
            if (prom==="sie") result = "sie/Sie"
        }
        if (sprache==="en"){
            if (prom==="ich") result = "I"
            if (prom==="du") result = "you"
            if (prom==="ersiees") result = "he/she/it"
            if (prom==="wir") result = "we"
            if (prom==="ihr") result = "you"
            if (prom==="sie") result = "they"
        }
        if (sprache==="es"){
            if (prom==="ich") result = "yo"
            if (prom==="du") result = "tú"
            if (prom==="ersiees") result = "él/ella/Ud."
            if (prom==="wir") result = "nosotros"
            if (prom==="ihr") result = "vosotros"
            if (prom==="sie") result = "ellos/ellas/Uds."
        }
        if (sprache==="fr"){
            if (prom==="ich") result = "je"
            if (prom==="du") result = "tu"
            if (prom==="ersiees") result = "il/elle"
            if (prom==="wir") result = "nous"
            if (prom==="ihr") result = "vous"
            if (prom==="sie") result = "ils/elles"
        }
        if (sprache==="it"){
            if (prom==="ich") result = "io"
            if (prom==="du") result = "tu"
            if (prom==="ersiees") result = "lei/lui"
            if (prom==="wir") result = "noi"
            if (prom==="ihr") result = "voi"
            if (prom==="sie") result = "loro"
        }
        if (sprache==="pt"){
            if (prom==="ich") result = "eu"
            if (prom==="du") result = "tu"
            if (prom==="ersiees") result = "ele/ela/você"
            if (prom==="wir") result = "nós"
            if (prom==="ihr") result = "vós"
            if (prom==="sie") result = "eles/elas/vocês"
        }
        return result


    }



export function setCookies(value, user){
        if (value){
            setCookie('cookie_speichern', 'ja', 90)
            setCookie('cookie_anmeldecode', user.anmeldecode, 90)
            setCookie('cookie_benutzername', user.benutzername, 90)   
        }
        else{
            setCookie('cookie_speichern', 'nein', 1)
            setCookie('cookie_anmeldecode', '', 1)
            setCookie('cookie_benutzername', '', 1)   
        }
    }


export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getGUID(){
    var nav = window.navigator;
    var screen = window.screen;
    var guid = nav.mimeTypes.length;
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';

    return guid;
}

export function stringToHML(string){

        let value = string
        
        return parse(value.replace(/\n/g, "<br/>"))

}

export function newSecretkey(){
        
        var speakeasy = require('speakeasy');

        var secret = speakeasy.generateSecret({length: 20});
         
        return secret.base32;

        // Example:  JFBVG4R7ORKHEZCFHZFW26L5F55SSP2Y
}
   
export function convertDate(value)  {   
        
        let options = {  
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',        
                };
        return new Date(value).toLocaleString("de-DE",options)             
               
}


export function convertDateTime(value)  {   

        if (value===null) return null
        
        let options = {  
                year: '2-digit',
                month: '2-digit',
                day: '2-digit', 
                hour:'2-digit',
                minute:'2-digit'       
                };
        return new Date(value).toLocaleString("de-DE",options)  + " Uhr"           
               
}


export function convertDateTimeSec(value)  {   

        if (value===null) return null
        
        let options = {  
                year: '2-digit',
                month: '2-digit',
                day: '2-digit', 
                hour:'2-digit',
                minute:'2-digit',
                second:'2-digit'       
                };
        return new Date(value).toLocaleString("de-DE",options)  + " Uhr"           
               
}


export function convertDateShort(value)  {   
        
        let options = {  
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',        
                };
        return new Date(value).toLocaleString("de-DE",options)   
}


export function convertDateWithoutYear(value)  {   
        
        let options = {  
                month: '2-digit',
                day: '2-digit',        
                };
        return new Date(value).toLocaleString("de-DE",options)   
}

export function chartTicks(istSekundarstufeII){
        let ticks = [0.8, 1, 2, 3, 4, 5, 6]      
        if (istSekundarstufeII){
                ticks=[16,15,14,13,12,11,10,9,8,7,6,5,4,3,2,1,0]
        }
        return ticks
}


export function convertTime(datum,zeit)  {   
   
        let options = {  
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',  
                };

        const date=new Date(datum).toLocaleString("de-DE",options)            
        
        return  `${date} ${zeit.substr(0,5)} Uhr`    ;               
}

export function getFloatInFormat(value) {       

        return value
      
}

export function getSekundarStufeIINoteinInFormat(value) {
       
       
                return value.toString().replace(".", ",").substr(0,4)
    
       
}

export function getNoteInFormat(value) {
       
        if (value>0){
                return value.toString().replace(".", ",").substr(0,4)
        }
       // 

        return ''
}


export function getGeldFormat(value) {
       
        if (value>0){
                return value.toFixed(2)
        }
       // 

        return ''
}

export  function sort_by (field, reverse, primer){

        var key = primer ? 
            function(x) {return primer(x[field])} : 
            function(x) {return x[field]};
     
        reverse = !reverse ? 1 : -1;
     
        return function (a, b) {
            return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
          } 
}

export function randomComponentKey(){
       
        return '_' + Math.random().toString(36).substr(2, 9);
        
}

export function timeConverter(timestamp){
        var dt = new Date(timestamp*1000);
        var hr = dt.getHours();
        var m = "0" + dt.getMinutes();
        var s = "0" + dt.getSeconds();
        return hr + ':' + m.substr(-2) + ':' + s.substr(-2);  
}

export function clearArray(value){

      
        let string = value
       
        string = value
        string = string.replace('{', '')
        string = string.replace('}', '')
        string = string.replace(/"/g, '')
        string = string.replace(/,/g, ', ')               
       

      //  {"Herbert Dapper","Dominik Mast"}


        return string
}

export function clearErledigtArray(value){

      //{"2020-04-29 12:50:45.89579","2020-04-29 12:50:45.91139"}
        let string = value       
       
        string = string.replace('{', '')
        string = string.replace('}', '')    
        string = string.replace(/"/g, '')  
       

        return string
}





export function clearEmailArray(value){

      
        let string = value       
        string = string.replace('{', '')
        string = string.replace(';}', '')    
        string = string.replace('}', '')     
        string = string.replace(/;,/g, ', ')  
        string = string.replace(/;/g, ', ')          
       
//{anne.dapper@alpenblick.sh;hp.abt@alpenblick.sh;,andreas.brugger@alpenblick.sh;}

        return string
}


export function  shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }


export default newSecretkey

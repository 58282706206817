import React from 'react';
import browserHistory from 'react-router/lib/browserHistory';
import axios from 'axios';
import { logoutUser } from '../actions';
import { connect } from 'react-redux';
import FeatherIcon from 'feather-icons-react'
import Modal from '../components/modal';
import $ from 'jquery';
import {shuffleArray} from '../components/generalfunctions.jsx'
import SchulePanel from '../components/schulepanel'
import 'material-icons/iconfont/material-icons.css';


class Admin extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
            activeSchule: null ,
            schulen:[],
            errortext: undefined ,
            activeKey:'schulen'  ,
            benutzer:[],
            activeBenutzer:null,
            showNavbar:false
        }
        this.speichereSchule = this.speichereSchule.bind(this)
        this.getSchulen = this.getSchulen.bind(this)
        this.neueLeereSchule = this.neueLeereSchule.bind(this)
        this.aktualisiereSchule = this.aktualisiereSchule.bind(this)
        this.schuleRow = this.schuleRow.bind(this)
        this.editSchule = this.editSchule.bind(this)
        this.loescheSchule = this.loescheSchule.bind(this)
        this.topNavigation = this.topNavigation.bind(this)
        this.getBenutzer = this.getBenutzer.bind(this)
        this.benutzerRow = this.benutzerRow.bind(this)
        this.benutzerTabelle = this.benutzerTabelle.bind(this)
        this.editBenutzer = this.editBenutzer.bind(this)
        this.checkSession =this.checkSession.bind(this)
        this.abmelden = this.abmelden.bind(this)    
    }

    editProfile(){

    }

    checkSession() {
        axios.get('/api/session')
            .then(
                response => {
                    if (this.props.activeUser !==undefined && this.props.activeUser.id !==response.data) {
                        browserHistory.push('/login')
                   }
               }
            )
            .catch(error => {
                if (error.response.status ===401) {
                    browserHistory.push('/login')
               }
           })
   }


    abmelden() {
        this.props.logoutUser().
        then(
            result => {
                browserHistory.push('/login')
            }
        )

    }

    getSchulen(){
        axios.get("/api/schulen")
        .then(
            result=>{
                if (result.data !==null){
                    this.setState({schulen:result.data})
                }
            }
        )
    }
    getBenutzer(){
        axios.get("/api/benutzer/admin")
        .then(
            result=>{
                if (result.data !==null){
                    this.setState({benutzer:result.data})
                }
            }
        )
    }

    speichereSchule(){

        const _this = this

        axios.post("/api/schulen", this.state.activeSchule)
        .then(
            result=>{
                if (result.data !==null){

                    this.setState({schulen:result.data})
                    
                    if (this.state.activeSchule.id==0){
                     const sql="SELECT LAST_INSERT_ID()"
                     const array = {
                         sql
                     }
                     axios.post('/api/execcommonquery/integer', array)
                     .then(
                        result=>{
                            let activeSchule = this.state.activeSchule;
                            activeSchule.id = result.data
                            this.setState({activeSchule})
                        }
                     )
                } 
            }               
            }
        )
     

    }

    aktualisiereSchule(e){

        let activeSchule = this.state.activeSchule;
        if (e.target.name==="name1") activeSchule.name1 = e.target.value;
        if (e.target.name==="name2") activeSchule.name2 = e.target.value;
        if (e.target.name==="anschrift1") activeSchule.anschrift1 = e.target.value;
        if (e.target.name==="anschrift2") activeSchule.anschrift2 = e.target.value;
        if (e.target.name==="plz") activeSchule.plz = e.target.value;
        if (e.target.name==="ort") activeSchule.ort = e.target.value;
        if (e.target.name==="land") activeSchule.land = e.target.value;
        if (e.target.name==="telefon1") activeSchule.telefon1 = e.target.value;
        if (e.target.name==="email1") activeSchule.email1 = e.target.value;
        if (e.target.name==="telefon2") activeSchule.telefon2 = e.target.value;
        if (e.target.name==="email2") activeSchule.email2 = e.target.value;
        if (e.target.name==="rechnungsanschrift1") activeSchule.rechnungsanschrift1 = e.target.value;
        if (e.target.name==="rechnungsanschrift2") activeSchule.rechnungsanschrift2 = e.target.value;
        if (e.target.name==="rechnungsplz") activeSchule.rechnungsplz = e.target.value;
        if (e.target.name==="rechnungsort") activeSchule.rechnungsort = e.target.value;
        if (e.target.name==="rechnungsland") activeSchule.rechnungsland = e.target.value;

        this.setState({activeSchule})

    }

    componentWillMount(){
       // this.checkSession()
        this.getSchulen();
        this.getBenutzer()
    }

    modalSchuledialog(){      

        if (this.state.activeSchule===null  ) return null

        const body = <SchulePanel activeSchule={this.state.activeSchule}  aktualisiereSchule={this.aktualisiereSchule} />

        
    
    
        const okButtonenabled = (this.state.activeSchule.name1!=="");    
    
        return (
            <Modal 
                modalid="modalSchuledialog"
                buttonid="modalSchuledialogButton"  
                title={this.state.activeSchule.name1}
                body={body} 
                okButtonClick={this.speichereSchule}  
                okButtonenabled={okButtonenabled} 
                errortext={this.state.errortext} 
                okButtonTitle="OK"
            />
        )
    }

       loescheSchule(){
           const _this = this
           axios.get(`/api/schulen/delete/${this.state.activeSchule.id}`)
           .then(
               result=>{
                   if (result.data !==null){
                       this.setState({schulen:result.data},
                        function(){
                            _this.getBenutzer()
                        })
                   }                   
               }
           )
           $('.modal-backdrop').remove()
       }


       modalDeleteDialog(){

        if (this.state.activeSchule ===null) return null;

        let frage = `Möchten Sie die Schule "${this.state.activeSchule.name1} ${this.state.activeSchule.ort} " löschen? Warnung! Alle mit der Schule verbundenen Benutzer und verbundene Daten werden ebenfalls gelöscht!`

        return (
            <Modal 
                modalid="modalDeleteDialog"
                buttonid="modalDeleteDialogButton"  
                title="Schule löschen" 
                body={frage} 
                okButtonClick={this.loescheSchule} 
                okButtonTitle="Löschen" 
                okButtonenabled={true} 
                errortext={this.state.errortext} />
        )
           

       }

       neueLeereSchule(){
          const activeSchule =  {
            id:0,
            name1:'Neue Schule',
            name2:'',
            anschrift1:'',
            anschrift2:'',
            land:'CH',
            plz:'',
            ort:'',
            telefon1:'',
            email1:'',
            telefon2:'',
            email2:'',
            rechnungsanschrift1:'',
            rechnungsanschrift2:'',
            rechnungsplz:'',
            rechnungsort:'',
            rechnungsland:''
           }

           this.setState({activeSchule}, function(){$('#modalSchuledialogButton').click()})

           



    }

    editSchule(schule){
        this.setState({activeSchule:schule}, function(){

           $('#modalSchuledialogButton').click()  
        })    
    }

    schuleRow(schule){
        let num = this.state.schulen.indexOf(schule) + 1

        let liAnsprechpartner=null

        if (schule.schule_ansprechpartner.length>0){
            let ansprechpartner =[]
            schule.schule_ansprechpartner.forEach(benutzer => {
                ansprechpartner.push(<li key={benutzer.id}>{benutzer.namevoll}, {benutzer.email}</li>)
            });
            liAnsprechpartner=
            <>
            <ul>
                {ansprechpartner}
            </ul><br/></>
        }
        
        return(
            <tr key={schule.id}>
                <td>{num}</td>
                <td>{schule.name1} {schule.name2} </td>
                <td>{schule.anschrift1} {schule.anschrift2}<br/>{schule.land}-{schule.plz} {schule.ort} <br/>{schule.email1}<br/>{schule.telefon1}</td>
                <td>{liAnsprechpartner}</td>
                <td><button className="btn btn-primary btn-sm" onClick={()=>this.editSchule(schule)}> <FeatherIcon icon="edit" size="1em" /></button>    <button className="btn btn-danger btn-sm" onClick={()=>this.setState({activeSchule:schule}, function(){$('#modalDeleteDialogButton').click()})}> <FeatherIcon icon="trash" size="1em" /></button>
                </td>
            </tr>
        )
    }

    schuleTabelle(){
        return(
            <div className="table-responsive">

            <table className="table table-striped">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Schulname</th>
                    <th>Anschrift</th>
                    <th>Ansprechpartner</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.schulen.map(this.schuleRow)}   
                </tbody>
                </table>
            </div>
        )
    }

    editBenutzer(benutzer){

    }


    benutzerRow(benutzer){
        let num = this.state.benutzer.indexOf(benutzer) + 1

        let divSchule = null
        if (benutzer.schule !==null){
            divSchule = (
                <div>
                    {benutzer.schule.name1}<br/>{benutzer.schule.name2}<br/>
                    {benutzer.schule.ansprechpartner1}<br/>
                    {benutzer.schule.email1}<br/>{benutzer.schule.telefon1}
                </div>
            )
        }

        return(
            <tr key={benutzer.id}>
                <td>{num}</td>
                <td>{benutzer.benutzername},  {benutzer.namevoll} </td>
                <td>{benutzer.email}</td>
                <td>{benutzer.anmeldecode}</td>
                <td>{divSchule}</td>
                <td><button className="btn btn-primary btn-sm" onClick={()=>this.editBenutzer(benutzer)}> <FeatherIcon icon="edit" size="1em" /></button> <button className="btn btn-danger btn-sm" onClick={()=>this.setState({activeBenutzer:benutzer})}> <FeatherIcon icon="trash" size="1em" /></button>
                </td>
            </tr>
        )
    }


    benutzerTabelle(){
        return(
            <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Benutzername, Name</th>
                    <th>Email</th>
                    <th>Email</th>
                    <th>Schule</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.benutzer.map(this.benutzerRow)}   
                </tbody>
            </table>
            </div>
        )
    }


    topNavigation(){

        let navLinkSchulen = <a className="nav-link" data-bs-toggle="tab" href="#" onClick={()=>this.setState({activeKey:'schulen'})} role="tablist" aria-expanded="true">Schulen</a>
        if (this.state.activeKey == 'schulen') navLinkSchulen = <a className="nav-link active"  href="#" onClick={()=>this.setState({activeKey:'schulen'})} role="tablist" aria-expanded="true">Schulen</a>
        let navLinkBenutzer = <a className="nav-link" data-bs-toggle="tab" href="#" onClick={()=>this.setState({activeKey:'benutzer'})} role="tablist" aria-expanded="true">Benutzer</a>
        if (this.state.activeKey == 'benutzer') navLinkBenutzer = <a className="nav-link active"  href="#" onClick={()=>this.setState({activeKey:'benutzer'})} role="tablist" aria-expanded="true">Benutzer</a>

        return(

<>
            <ul className="nav nav-pills nav-pills-primary justify-content-center" role="tablist">
                <li className="nav-item">
                    {navLinkSchulen}
                </li>
                <li className="nav-item">
                   
                        {navLinkBenutzer}
                </li>
            </ul>
            <hr/>
            </>
        )       
    }

    editProfile(){
       
    }

    navBar(){
        return (
            <div>           
                <div className="rightalign" style={{marginRight:'15px',marginTop:'15px'}}    >
                    <button id="minimizeSidebar" className="btn btn-just-icon btn-white btn-fab btn-round" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                        <i className="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                        <i className="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
                    </button> 
                </div>
                
                
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{backgroundColor:'black'}}>
                    <div className="offcanvas-header">                       
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" style={{backgroundColor:'white', color:'#ffffff'}}></button>
                    </div>
                    <div className="offcanvas-body off-canvas">  

                     <button className="btn btn-primary"     onClick={this.abmelden}><i className="material-icons"> clos </i> Abmelden </button>             
                                    
                                   

                    </div>
                </div>
            </div>
        )
    }



    render(){


        const neueSchuleButton =     <button className="btn btn-primary" onClick={this.neueLeereSchule}>Schule + </button>
        let neueSchuleButton2 = neueSchuleButton;
        if (this.state.schulen.length<10){
            neueSchuleButton2 = null;
        }
      
        let content = null;

        if (this.state.activeKey==="schulen"){
            content=(
                <>
                    <div  style={{marginBottom:'15px'}} className="rightalign">{neueSchuleButton}</div>                
                    {this.schuleTabelle()}                
                    {neueSchuleButton2}
                    </>
            )
        }
        if (this.state.activeKey==="benutzer"){
            content=(
                <div style={{marginTop:'25px'}}>
                {this.benutzerTabelle()}
                </div>
            )
        }

        return (

     
               <div className="main">  
                 {this.navBar()}    
        
                 <div className="container">

                    <div className="card">
                        <div className="card-body">
                        {this.topNavigation()}
                        {content}
                        </div>
                    </div>
                </div>
                {this.modalSchuledialog()} {this.modalDeleteDialog()}     
            </div>      

 
            
            
        )
    }

}

function mapStateToProps ({ posts }) {
  return {
    activeUser: posts.userData,
  };
};

export default connect(mapStateToProps, {logoutUser}) (Admin);

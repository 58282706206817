import React from 'react'

import 'material-icons/iconfont/material-icons.css';

const SchuelerPanel =(props) => {   

      if (props.activeSchueler===undefined)    return null 

         return(
            
              <div className="table-responsive">
                   
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>Benutzername *</td><td><input value={props.activeSchueler.benutzername} className="form-control" name="benutzername"  type="text" placeholder="Benutzername" onChange={props.aktualisiereSchueler} /></td>
                            </tr>
                            <tr>
                                <td>Email (optional)</td><td><input value={props.activeSchueler.email} className="form-control" name="email" aria-describedby="emailHelp" type="email" placeholder="Email" onChange={props.aktualisiereSchueler} />
                                <small id="emailHelp" className="form-text text-muted">Normalerweise müssen Schülerinnen und Schüler nicht mit einer eigenen Emailadresse registriert werden, denn sie erhalten ihren Anmeldecode von der Lehrperson. Ist jedoch eine Emailadresse bekannt, können sich die SuS ihren vergessenen Anmeldecode über die Passwort-Vergessen-Funktion zuschicken lassen, was die Arbeit der Lehrperson durchaus erleichtern kann.</small></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div className="togglebutton">
                                        <label>
                                            <input type="checkbox" name="kann_stapel_veroeffentlichen" checked={props.activeSchueler.kann_stapel_veroeffentlichen===1} onChange={props.aktualisiereSchueler}/>
                                            <span className="toggle"></span>
                                            kann Kartenstapel veröffentlichen
                                        </label>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
        )

}

export default SchuelerPanel